import React from "react";
import "./Titolo.css"; // Assicurati di importare il tuo file CSS per lo stile

interface TitoloProps {
    title: string;
    backgroundColor: string; // Percorso dell'immagine di sfondo
}

function Titolo({ title, backgroundColor }: TitoloProps) {
    return (
        <div className="titolo-container">
            <section
                className="titolo-section"
                style={{ backgroundColor: `${backgroundColor}` }}
            >
                <div className="overlay"></div>

                <div className="section-content">
                    <div className="container">
                        <div className="row">
                            <h1 className="display-1 titolo-title border-bottom border-4">{title}</h1>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
}

export default Titolo;
