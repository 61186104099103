import React from "react";


function GenitoriAttivi() {
    return(
        <div className="d-flex flex-column text-center m-auto" style={{maxWidth: "30em", margin: "1em" ,padding: "2em", border: "solid", borderColor: "#2F719D", borderWidth: "6px", borderRadius: "1em"}}>
            <h3 style={{borderBottom: "solid", color: "#64A6D0", fontFamily: "Hammersmith One"}}>Genitori attivi</h3>
            <p>
                Il genitore del bambino iscritto a scuola nuoto o danza può frequentare la palestra nello stesso orario <br/>
                La durata di questa iscrizione collimerà in ogni caso con la scadenza del corso del bambino, senza ridurre il prezzo.
                <br/>
                <br/>
                <strong>3 mesi</strong> 104 € + iscrizione 10 €
            </p>
        </div>
    )
}

export default GenitoriAttivi