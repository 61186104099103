import React, { useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";

interface CardNewsProps {
    foto: string,
    titolo: string,
    descrizione: string,
    data: string,
    indirizzo: string,
    settore1: string,
    settore2?: string
    posizioneFoto: string
}

function CardNews({ foto, titolo, descrizione, data, indirizzo, settore1, settore2, posizioneFoto }: CardNewsProps) {
    const [isHovered, setIsHovered] = useState(false);
    const navigate = useNavigate();

    const handleMouseEnter = () => {
        setIsHovered(true);
    };

    const handleMouseLeave = () => {
        setIsHovered(false);
    };

    return (
        <div
            className="card"
            style={{
                width: "19em",
                height: "26em",
                margin: "1.5em",
                border: "solid",
                borderWidth: "5px",
                borderColor: "#032041",
                borderRadius: "10px",
                padding: "2em",
                backgroundColor: isHovered ? "#447aa0" : "transparent",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center"
            }}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
        >
            <div onClick={() => navigate(`${indirizzo}`)} style={{ cursor: "pointer", color: "#032041" }}>
                <p className="text-center" style={{ fontSize: "22px", fontFamily: "Hammersmith One" }}>{data}</p>
                <img src={foto} className="card-img-top" alt="..." style={{ maxHeight: "10em", objectFit: "cover", objectPosition: `${posizioneFoto}` }} />
                <div className="card-body" onClick={() => navigate(`${indirizzo}`)} style={{ cursor: "pointer" }}>
                    <h3 className="text-center" style={{ fontFamily: "Hammersmith One" }}>{titolo}</h3>
                    <p className="text-center">{descrizione}</p>
                </div>
            </div>
            <div className="card-footer" style={{ position: "absolute", bottom: "0", width: "100%", backgroundColor: "#032041", color: "white", padding: "0.5em", textAlign: "center" }}>
                <NavLink to={`/${settore1}`} style={{ color: "white" }}>{settore1}</NavLink>
                {
                    settore2 && <NavLink to={`/${settore2}`} style={{ color: "white", marginLeft: "6px" }}>{settore2}</NavLink>
                }
            </div>
        </div>
    );
}

export default CardNews;
