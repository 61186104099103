import React from "react";
import foto from "../images/multitessere_card_small.png"
import {useNavigate} from "react-router-dom";
import "../styles/CardMultitessere.css"

function CardMultitessere() {
    const navigate = useNavigate()
    return(
        <div className="card-multitessere d-flex flex-row align-self-center"
             onClick={()=> navigate("/multitessere")}
        >
            <img src={foto} alt="Immagine" style={{ width: '100%', border: "solid", borderRadius: "1em", overflow: "hidden" }} />
            <div className="card-multitessere-text"></div>
        </div>
    )
}

export default CardMultitessere
