import React from "react";
import TitoloColore from "../commons/TitoloColore";
import ScrollToTopOnMount from "../components/ScrollToTopOnMount";
import Space from "../commons/Space";


function VisiteMediche() {
    return(
        <div>
            <ScrollToTopOnMount />
            <TitoloColore title={"Visite Mediche"} backgroundColor={"#003F83"} />
            <Space />
            <p className="text-center">Pagina in costruzione...</p>
            <Space />
        </div>
    )
}

export default VisiteMediche