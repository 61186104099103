import React from "react";
import CaroselloStandard from "../../commons/CaroselloStandard";
import foto0 from "../../images/Nuoto/Nuoto_libero/IMG_1555_tagliata.jpg"
import foto3 from "../../images/Nuoto/Nuoto_libero/IMG_1671.jpg"
import foto4 from "../../images/Nuoto/Nuoto_libero/DSCN1015.jpg"
import foto5 from "../../images/Nuoto/Nuoto_libero/DSCN0974.jpg"
import foto2 from "../../images/Nuoto/Nuoto_libero/IMG_8683.jpg"
import foto1 from "../../images/Nuoto/Nuoto_libero/IMG_8661.jpg"

import Titolo from "../../commons/Titolo";
import NuotoLiberoAccordion from "../../components/nuoto_libero/NuotoLiberoAccordion";
import ScrollToTopOnMount from "../../components/ScrollToTopOnMount";
import "./Indietro.css"
import {useNavigate} from "react-router-dom";
import Space from "../../commons/Space";
import Spa from "../Spa";
import CardMultitessere from "../../components/CardMultitessere";
import TitoloSezioni from "../../commons/TitoloSezioni";
import FrecciaIndietro from "../../commons/FrecciaIndietro";


function NuotoLibero() {
    const navigate = useNavigate()
    return(
        <div>
            <ScrollToTopOnMount />
            <Titolo backgroundImage={foto0} title={"Nuoto Libero"}/>
            <FrecciaIndietro indirizzo={"/piscina"} />
            <br/>

            <figure className="text-center">
                <blockquote className="blockquote">
                    <p>
                        <h4>Corsie vasca grande sempre disponibili</h4>
                        <h5>in assenza di corsi nuoto è possibile utilizzare anche la vasca piccola</h5>
                        <br/>
                        Spogliatoi con docce e phon liberi

                    </p>
                </blockquote>
            </figure>
            <br/>
                <NuotoLiberoAccordion />
            <Space />
            <div className="alert alert-primary d-flex align-items-center m-auto" role="alert">
                <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" className="bi bi-info-circle-fill flex-shrink-0" viewBox="0 0 16 16">
                    <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16m.93-9.412-1 4.705c-.07.34.029.533.304.533.194 0 .487-.07.686-.246l-.088.416c-.287.346-.92.598-1.465.598-.703 0-1.002-.422-.808-1.319l.738-3.468c.064-.293.006-.399-.287-.47l-.451-.081.082-.381 2.29-.287zM8 5.5a1 1 0 1 1 0-2 1 1 0 0 1 0 2"/>
                </svg>
                <div className="text-center" style={{ marginLeft: "2em" }}>
                    Richiesta buona capacità natatoria con stile e respirazione
                </div>
            </div>
            <Space />
                <CaroselloStandard foto1={foto1} foto2={foto2} foto3={foto3} foto4={foto4} foto5={foto5}/>
            <Space />

            <div className="d-flex flex-column text-center align-items-center justify-content-center">
                <TitoloSezioni title={"Multitessere"} />
                <h4 style={{fontFamily: "Hammersmith One"}}>Scopri le nostre multitessere</h4>
                <CardMultitessere />
            </div>
            <Space />

        </div>
    )
}


export default NuotoLibero
