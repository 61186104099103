import React from "react";
import "../../styles/Tabella.css"


function TabellaOrarioRidotto() {
    return(
        <div className="table-container">
            <table className="table table-bordered m-auto" style={{width: "20em"}}>
                <colgroup>
                    <col style={{ minWidth: "50px"}} />
                    <col style={{ minWidth: "50px" }} />

                </colgroup>
                <thead>
                <tr>
                    <th className="header-cell" scope="col">Giorno</th>
                    <th className="header-cell" scope="col">Orario</th>
                </tr>
                </thead>
                <tbody>
                <tr>
                    <td className="data-cell">lunedì</td>
                    <td className="data-cell">7-18</td>
                </tr>
                <tr>
                    <td className="data-cell">martedì</td>
                    <td className="data-cell">9-18</td>
                </tr>
                <tr>
                    <td className="data-cell">mercoledì</td>
                    <td className="data-cell">7-18</td>
                </tr>
                <tr>
                    <td className="data-cell">giovedì</td>
                    <td className="data-cell">9-18</td>
                </tr>
                <tr>
                    <td className="data-cell">venerdì</td>
                    <td className="data-cell">7-18</td>
                </tr>
                <tr>
                    <td className="data-cell">sabato</td>
                    <td className="data-cell">9-19</td>
                </tr>
                <tr>
                    <td className="data-cell">domenica</td>
                    <td className="data-cell">9-13</td>
                </tr>
                </tbody>
            </table>
        </div>
    )
}

export default TabellaOrarioRidotto