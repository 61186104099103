import React, { useState, useEffect } from "react";
import "../../styles/MultitessereSezioni.css";

function MultitessereSezioni() {
    const sections = [
        {
            id: "collapseULPS",
            label: "ULPS",
            content:
                <div>
                    <figure className="text-center">
                        <blockquote className="blockquote">
                            <p>
                                <h1 className="d-flex flex-row justify-content-center m-auto" style={{borderBottom: "solid", width: "5em", fontFamily: "Hammersmith One"}}>ULPS</h1>

                                <strong>Nuoto libero<br/> + <br/> SPA <br/></strong>
                                <br/>
                                <strong style={{fontSize: "18px"}}>Orario pieno</strong> <br/>
                                <strong>3 mesi </strong> 288 € -
                                <strong> 6 mesi </strong> 492 € -
                                <strong> 12 mesi </strong> 720 €
                            </p>
                            <div style={{fontSize: "16px"}}>*nel periodo natalizio e in agosto vale come tessera SUPER</div>
                        </blockquote>
                    </figure>
                </div>,
        },
        {
            id: "collapsePLUS",
            label: "PLUS",
            content: <div>
                <figure className="text-center">
                    <blockquote className="blockquote">
                        <p>
                            <h1 className="d-flex flex-row justify-content-center m-auto" style={{borderBottom: "solid", width: "5em", fontFamily: "Hammersmith One"}}>PLUS</h1>
                            <strong>Palestra<br/> + <br/> SPA <br/></strong>
                            <br/>
                            <strong style={{fontSize: "18px"}}>Orario pieno</strong> <br/>
                            <strong>3 mesi </strong> 336 € -
                            <strong> 6 mesi </strong> 528 € -
                            <strong> 12 mesi </strong> 792 €
                        </p>
                        <div style={{fontSize: "16px"}}>*nel periodo natalizio e in agosto vale come tessera SUPER</div>
                    </blockquote>
                </figure>
            </div>,
        },
        {
            id: "collapseTRIS",
            label: "TRIS",
            content: <div>
                <figure className="text-center">
                    <blockquote className="blockquote">
                        <p>
                            <h1 className="d-flex flex-row justify-content-center m-auto" style={{borderBottom: "solid", width: "5em", fontFamily: "Hammersmith One"}}>TRIS</h1>
                            Tre corsi a scelta tra:<br/>
                            <strong>Palestra, attività di gruppo/runnering, scuola nuoto</strong><br/>
                            <br/>
                            <strong style={{fontSize: "18px"}}>Orario pieno</strong> <br/>
                            <strong>3 mesi </strong> 336 € -
                            <strong> 6 mesi </strong> 528 € -
                            <strong> 12 mesi </strong> 792 €
                        </p>
                        <div style={{fontSize: "16px"}}>*nei periodi senza corsi di gruppo vale come tessera SUPER</div>
                    </blockquote>
                </figure>
            </div>,
        },
        {
            id: "collapsePOKER",
            label: "POKER",
            content: <div>
                <figure className="text-center">
                    <blockquote className="blockquote">
                        <p>
                            <h1 className="d-flex flex-row justify-content-center m-auto" style={{borderBottom: "solid", width: "5em", fontFamily: "Hammersmith One"}}>POKER</h1>
                            <strong>Tutti i corsi  di gruppo fitness di intervallo e sera</strong><br/>
                            <br/>
                            <strong style={{fontSize: "18px"}}>Orario pieno</strong> <br/>
                            <strong>3 mesi </strong> 248 € -
                            <strong> 6 mesi </strong> 408 € -
                            <strong> 12 mesi </strong> 560 €
                        </p>
                    </blockquote>
                </figure>
            </div>,
        },
        {
            id: "collapseSUPER",
            label: "SUPER",
            content: <div>
                <figure className="text-center">
                    <blockquote className="blockquote">
                        <p>
                            <h1 className="d-flex flex-row justify-content-center m-auto" style={{borderBottom: "solid", width: "5em", fontFamily: "Hammersmith One"}}>SUPER</h1>
                            <strong>Nuoto libero <br/> + <br/>SPA</strong><br/>
                            <strong>+ </strong><br/>un corso a scelta tra:<br/>
                            <strong>palestra, attività di gruppo, scuola nuoto</strong>
                        </p>
                    </blockquote>
                </figure>
                <table className="table my-table text-center">
                    <thead >
                    <tr >
                        <th scope="col" ></th>
                        <th scope="col">
                            Orario pieno
                            <div style={{fontSize: "11px"}}><br/></div>
                        </th>
                        <th scope="col" style={{width: "20%"}}>
                            Orario ridotto
                            <div style={{fontSize: "11px"}}>(Nei feriali fino alle 18)</div>
                        </th>
                        <th scope="col" style={{width: "20%"}}>
                            Super mattino
                            <div style={{fontSize: "11px"}}>(Nei feriali fino alle 13)</div>
                        </th>
                        <th scope="col">
                            Super intervallo
                            <div style={{fontSize: "11px"}}>(Nei feriali orario 12-16)</div>
                        </th>
                        <th scope="col">
                            Super weekend
                            <div style={{fontSize: "11px"}}>(sab: 9-19<br/> dom: 9-13)</div>
                        </th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr style={{backgroundColor: "#F8F8F8"}}>
                        <th scope="row">3 mesi</th>
                        <td>348 €</td>
                        <td>294 €</td>
                        <td></td>
                        <td></td>
                        <td>240 €</td>
                    </tr>
                    <tr>
                        <th scope="row">6 mesi</th>
                        <td>540 €</td>
                        <td>504 €</td>
                        <td></td>
                        <td></td>
                        <td>312 €</td>
                    </tr>
                    <tr>
                        <th scope="row">12 mesi</th>
                        <td>864 €</td>
                        <td>708 €</td>
                        <td>696 €</td>
                        <td>696 €</td>
                        <td>444 €</td>
                    </tr>
                    </tbody>
                </table>
                <div style={{fontSize: "16px"}}>
                    *le limitazioni orarie non si applicano sabato, domenica, nei semi festivi, nel mese di agosto e nel periodo natalizio; <br/>
                    negli stessi periodi la tessera SUPER WEEKEND vale anche nei giorni feriali, senza limiti di orario
                </div>
                <figure className="text-center">
                    <blockquote className="blockquote">
                        <p >
                            <br/>
                            <h1 className="d-flex flex-row justify-content-center m-auto" style={{borderBottom: "solid", width: "10em", fontFamily: "Hammersmith One"}}>SUPER RUN</h1>
                            <strong>Runnering illimitato + SPA + palestra/nuoto libero </strong><br/>
                            <strong style={{fontSize: "16px"}}>Orario pieno</strong> <br/>
                            <div style={{fontSize: "16px"}}><strong style={{fontSize: "16px"}}>12 mesi</strong> 630 €</div>
                        </p>
                    </blockquote>
                </figure>
            </div>

            ,
        },
        {
            id: "collapseOPEN",
            label: "OPEN",
            content: <div>
                <figure className="text-center">
                    <blockquote className="blockquote">
                        <p>
                            <h1 className="d-flex flex-row justify-content-center m-auto" style={{borderBottom: "solid", width: "5em", fontFamily: "Hammersmith One"}}>OPEN</h1>
                            <strong>Tutti i corsi e utenze libere</strong><br/>
                            <div style={{fontSize: "16px"}}>
                            </div>
                        </p>
                    </blockquote>
                </figure>
                <table className="table my-table text-center" style={{backgroundColor: "#F8F8F8"}}>
                    <thead>
                    <tr>
                        <th scope="col"></th>
                        <th scope="col">
                            Orario pieno
                            <div style={{fontSize: "11px"}}><br/></div>
                        </th>
                        <th scope="col">
                            Super mattino
                            <div style={{fontSize: "11px"}}>(Nei feriali fino alle 13)</div>
                        </th>
                        <th scope="col">
                            Super intervallo
                            <div style={{fontSize: "11px"}}>(Nei feriali orario 12-16)</div>
                        </th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <th scope="row">3 mesi</th>
                        <td>396 €</td>
                        <td></td>
                        <td></td>
                    </tr>
                    <tr>
                        <th scope="row">6 mesi</th>
                        <td>662 €</td>
                        <td></td>
                        <td></td>
                    </tr>
                    <tr>
                        <th scope="row">12 mesi</th>
                        <td>974 €</td>
                        <td>840 €</td>
                        <td>840 €</td>
                    </tr>
                    </tbody>
                </table>
            </div>,
        },
        {
            id: "collapseINGRESSI",
            label: "INGRESSI PISCINA E SPA",
            content: <div>
                <figure className="text-center">
                    <blockquote className="blockquote">
                        <p>
                            <h1 className="d-flex flex-row justify-content-center m-auto" style={{borderBottom: "solid", width: "15em", fontFamily: "Hammersmith One"}}>INGRESSI PISCINA E SPA</h1>
                            <div className="d-flex flex-row justify-content-center m-auto" style={{maxWidth: "20em", fontSize: "16px"}}></div>
                            <br/>
                            <strong>10 ingressi </strong> 159 €
                        </p>
                    </blockquote>
                </figure>
            </div>,
        },
    ];
    const [activePanel, setActivePanel] = useState<string | null>(
        sections.length > 0 ? sections[0].id : null
    );

    const handleButtonClick = (panelId: string) => {
        // Chiudi il pannello attivo se presente
        if (activePanel === panelId) {
            setActivePanel(null);
        } else {
            // Altrimenti, apri il nuovo pannello cliccato
            setActivePanel(panelId);
        }
    };



    return (
        <div className="container-fluid my-container d-flex flex-column m-auto " >
            <div
                className="d-flex flex-wrap p-flex"
                style={{
                    position: "relative",
                    zIndex: 1,
                    marginBottom: 0,
                    marginLeft: "8px",
                }}
            >
                {sections.map((section) => (
                    <button
                        key={section.id}
                        className={`btn btn-outline-secondary my-btn ${activePanel === section.id ? "active" : ""}`}
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target={`#${section.id}`}
                        aria-expanded={activePanel === section.id ? "true" : "false"}
                        aria-controls={section.id}
                        onClick={() => handleButtonClick(section.id)}
                    >
                        {section.label}
                    </button>
                ))}

            </div>
            <div>
                {sections.map((section) => (
                    <div
                        key={section.id}
                        className={`collapse ${activePanel === section.id ? "show" : ""}`}
                        id={section.id}
                    >
                        <div className="card card-body my-card justify-content-center">{section.content}</div>
                    </div>
                ))}
            </div>
        </div>
    );
}

export default MultitessereSezioni;
