import React from "react";

function TabellaAcquaFitMono() {
    return(
        <div className="table-container">
            <table className="table table-bordered m-auto" style={{width: "90%"}}>
                <thead>
                <tr>
                    <th className="header-cell" scope="col">Lun</th>
                    <th className="header-cell" scope="col">Mar</th>
                    <th className="header-cell" scope="col">Mer</th>
                    <th className="header-cell" scope="col">Gio</th>
                    <th className="header-cell" scope="col">Ven</th>
                    <th className="header-cell" scope="col">Sab</th>
                </tr>
                </thead>
                <tbody>
                <tr>
                    <td className="data-cell"></td>
                    <td className="data-cell"></td>
                    <td className="data-cell vasca-piccola">
                        9:15 - 10:00 <br/> <a href="#infoAcquaFitness" style={{color: "black"}}>Soft Water Gag</a>
                    </td>
                    <td className="data-cell"></td>
                    <td className="data-cell"></td>
                    <td className="data-cell"></td>
                </tr>
                <tr>
                    <td className="data-cell"></td>
                    <td className="data-cell"></td>
                    <td className="data-cell vasca-piccola">
                        10:00 -10:45 <br/> <a href="#infoAcquaFitness" style={{color: "black"}}>Soft Water Gag</a>
                    </td>
                    <td className="data-cell"></td>
                    <td className="data-cell"></td>
                    <td className="data-cell vasca-grande">
                        10:15 - 11:00 <br/> <a href="#infoAcquaFitness" style={{color: "black"}}>Deep Water</a>
                    </td>
                </tr>
                <tr>
                    <td className="data-cell"></td>
                    <td className="data-cell"></td>
                    <td className="data-cell"></td>
                    <td className="data-cell"></td>
                    <td className="data-cell"></td>
                    <td className="data-cell vasca-grande">
                        11:00 - 11:45 <br/> <a href="#infoAcquaFitness" style={{color: "black"}}>Deep Water</a>
                    </td>
                </tr>
                <tr>
                    <td className="data-cell"></td>
                    <td className="data-cell"></td>
                    <td className="data-cell vasca-grande">
                        12:30 - 13:30 <br/> <a href="#infoAcquaFitness" style={{color: "black"}}>Acqua Thriathlon</a>
                    </td>
                    <td className="data-cell"></td>
                    <td className="data-cell"></td>
                    <td className="data-cell"></td>
                </tr>
                <tr>
                    <td className="data-cell"></td>
                    <td className="data-cell"></td>
                    <td className="data-cell vasca-grande">
                        13:30 - 14:30 <br/> <a href="#infoAcquaFitness" style={{color: "black"}}>Acqua Thriathlon</a>
                    </td>
                    <td className="data-cell"></td>
                    <td className="data-cell"></td>
                    <td className="data-cell"></td>
                </tr>
                <tr>
                    <td className="data-cell"></td>
                    <td className="data-cell"></td>
                    <td className="data-cell vasca-grande">
                        19:15 - 20:00 <br/> <a href="#infoAcquaFitness" style={{color: "black"}}>Acqua Fitness</a>
                    </td>
                    <td className="data-cell"></td>
                    <td className="data-cell "></td>
                    <td className="data-cell"></td>
                </tr>
                <tr>
                    <td className="data-cell vasca-grande">
                        20:05 - 20:50 <br/> <a href="#infoAcquaFitness" style={{color: "black"}}>Deep Water</a>
                    </td>
                    <td className="data-cell"></td>
                    <td className="data-cell vasca-grande">
                        20:00 - 20:45 <br/> <a href="#infoAcquaFitness" style={{color: "black"}}>Acqua Fitness</a>
                    </td>
                    <td className="data-cell"></td>
                    <td className="data-cell"></td>
                    <td className="data-cell"></td>
                </tr>
                </tbody>
            </table>
        </div>
    )
}

export default TabellaAcquaFitMono