import React from "react"
import "../../styles/CardNuoto.css"
import CardHome from "./CardHome";
import acqua from "../../images/Nuoto/Nuoto_libero/IMG_1555_tagliata-2.jpg";
import palestra from "../../images/Palestra/IMG_9287.jpg";
import fitness from "../../images/Fitness/IMG_9222.jpg";
import danza from "../../images/Danza/IMG_1750.jpg";
import runnering from "../../images/Runnering/IMG_1719.jpg";
import spa from "../../images/Spa/IMG_1947.jpg";


function GroupCard() {
    return(
        <div className="card-container flex-column">
            <div className="d-flex flex-row flex-wrap justify-content-center" >
            <CardHome image={acqua} title={"Piscina"} indirizzo={"/piscina"} />
            <CardHome image={palestra} title={"Palestra"} indirizzo={"/palestra"} />
            <CardHome image={fitness} title={"Fitness"} indirizzo={"/fitness"} />
            </div>
            <div className="d-flex flex-row flex-wrap justify-content-center">
            <CardHome image={danza} title={"Danza"} indirizzo={"/danza"} />
            <CardHome image={runnering} title={"Runnering"} indirizzo={"/runnering"} />
            <CardHome image={spa} title={"Spa"} indirizzo={"/spa"} />
            </div>
        </div>
    )
}

export default GroupCard