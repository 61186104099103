import React from "react";
import "./Titolo.css"; // Assicurati di importare il tuo file CSS per lo stile

interface TitoloProps {
    title: string;
    backgroundImage: string; // Percorso dell'immagine di sfondo
}

function Titolo({ title, backgroundImage }: TitoloProps) {
    return (
        <div className="titolo-container">
            <section
                className="titolo-section"
                style={{ backgroundImage: `url(${backgroundImage})` }}
            >
                <div className="overlay"></div>

                <div className="section-content">
                    <div className="container">
                        <div className="row">
                                <h1 className="titolo-title flex-wrap">{title}</h1>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
}

export default Titolo;




