import React from "react";
import "../../styles/AllertIscrizione.css"


function AllertIscrizione () {
    return(
        <div className="alert alert-primary d-flex align-items-center m-auto" role="alert">
            <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" className="bi bi-info-circle-fill flex-shrink-0" viewBox="0 0 16 16">
                <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16m.93-9.412-1 4.705c-.07.34.029.533.304.533.194 0 .487-.07.686-.246l-.088.416c-.287.346-.92.598-1.465.598-.703 0-1.002-.422-.808-1.319l.738-3.468c.064-.293.006-.399-.287-.47l-.451-.081.082-.381 2.29-.287zM8 5.5a1 1 0 1 1 0-2 1 1 0 0 1 0 2"/>
            </svg>
            <div className="text-center" style={{ marginLeft: "2em" }}>
                I prezzi <strong>non includono</strong> il costo dell'iscrizione <br />
                3 mesi: 10 €,<div className="d-block d-sm-none" /> 6 mesi: 20 €, <div className="d-block d-sm-none" />12 mesi: 30 €
            </div>
        </div>

    )
}
export default AllertIscrizione