import React from "react";
import {useNavigate} from "react-router-dom";

interface FrecciaIndietroProps {
    indirizzo: string
}

function FrecciaIndietro ({indirizzo} : FrecciaIndietroProps) {
    const navigate =useNavigate()
    return(
        <div className="d-flex flex-row align-items-center">
            <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" fill="#2F719D" className="bi bi-arrow-left-circle-fill" viewBox="0 0 16 16"
                 style={{margin: "1em", cursor: "pointer"}}
                 onClick={()=> navigate(indirizzo)}
            >
                <path d="M8 0a8 8 0 1 0 0 16A8 8 0 0 0 8 0zm3.5 7.5a.5.5 0 0 1 0 1H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5z"/>
            </svg>
        </div>
    )
}
export default FrecciaIndietro