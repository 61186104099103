import React from "react";

import "../../styles/Accordion.css"
import TabellaAcquaFitBi from "./TabellaAcquaFitBi";
import TabellaAcquaFitMono from "./TabellaAcquaFitMono";
import FitnessLegenda from "../fitness/FitnessLegenda";


function AcquaFitnessAccordion() {
    return(
        <div className="accordion d-flex flex-column m-auto" id="accordionFlushExample">
            <div className="accordion-item">
                <h2 className="accordion-header d-flex flex-row" id="flush-headingOne" style={{ justifyContent: "center", textAlign: "center" }}>
                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-expanded="false" aria-controls="flush-collapseOne">
                        BISETTIMANALE
                    </button>
                </h2>
                <div id="flush-collapseOne" className="accordion-collapse collapse" aria-labelledby="flush-headingOne" data-bs-parent="#accordionFlushExample">
                    <div className="accordion-body">
                        <div style={{margin: "0 0 2em 5em"}}><FitnessLegenda color1={"#61D9FB"} label1={"Acqua alta"} /></div>
                        <TabellaAcquaFitBi />
                        <hr/>
                        <p className="text-center" style={{marginTop: "2em"}}>
                            <strong>3 mesi</strong> €252 + iscrizione €10 <br/>
                            <strong>6 mesi</strong> €372 + iscrizione €20 <br/>
                            <strong>9 mesi</strong> €492 + iscrizione €30 <br/>
                        </p>
                    </div>
                </div>
            </div>
            <div className="accordion-item">
                <h2 className="accordion-header" id="flush-headingTwo">
                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTwo" aria-expanded="false" aria-controls="flush-collapseTwo">
                        MONOSETTIMANALE
                    </button>
                </h2>
                <div id="flush-collapseTwo" className="accordion-collapse collapse" aria-labelledby="flush-headingTwo" data-bs-parent="#accordionFlushExample">
                    <div className="accordion-body">
                        <div style={{margin: "0 0 2em 3em"}}><FitnessLegenda color1={"#61D9FB"} label1={"Acqua alta"} color2={"#FFAEA5"} label2={"Acqua bassa - per chi non sa nuotare"}/></div>
                        <TabellaAcquaFitMono />
                        <hr/>
                        <p className="text-center" style={{marginTop: "2em"}}>
                            <strong>3 mesi</strong> €204 + iscrizione €10 <br/>
                            <strong>6 mesi</strong> €312 + iscrizione €20 <br/>
                            <strong>9 mesi</strong> €396 + iscrizione €30 <br/>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AcquaFitnessAccordion
