import React, {useState} from "react";

function DanzaPacchettiMedia() {
    const sections = [
        {
            id: "collapseONE",
            label: "EXPERIENCE",
            content: <div>
                <figure className="text-center">
                    <blockquote className="blockquote">
                        <p>
                            <h2 className="d-flex flex-row justify-content-center m-auto" style={{borderBottom: "solid", width: "10em", fontFamily: "Hammersmith One"}}>EXPERIENCE</h2>
                            <br/>
                            <strong> 12 mesi </strong> 620 € + iscrizione 30 €
                        </p>
                    </blockquote>
                </figure>
            </div>,
        },
        {
            id: "collapseTWO",
            label: "EXPERIENCE PLUS",
            content: <div>
                <figure className="text-center">
                    <blockquote className="blockquote">
                        <p>
                            <h2 className="d-flex flex-row justify-content-center m-auto" style={{borderBottom: "solid", width: "10em", fontFamily: "Hammersmith One"}}>EXPERIENCE PLUS</h2>
                            <br/>
                            <strong> 12 mesi </strong> 670 € + iscrizione 30 €
                        </p>
                    </blockquote>
                </figure>
            </div>,
        }
    ];
    const [activeIndex, setActiveIndex] = useState(-1);

    const handleButtonClick = (index:any) => {
        // Chiudi tutte le sezioni se sono già aperte
        setActiveIndex((prevIndex) => (prevIndex === index ? -1 : index));
    };

    return (
        <div className="accordion m-auto" id="accordionExample" style={{ width: "90%" }}>
            {sections.map((section, index) => (
                <div key={section.id} className="accordion-item">
                    <h2 className="accordion-header" id={`heading${index + 1}`}>
                        <button
                            className={`accordion-button ${index !== activeIndex ? "collapsed" : ""}`}
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target={`#${section.id}`}
                            aria-expanded={index === activeIndex ? "true" : "false"}
                            aria-controls={section.id}
                            onClick={() => handleButtonClick(index)}
                        >
                            {section.label}
                        </button>
                    </h2>
                    <div
                        id={section.id}
                        className={`accordion-collapse collapse ${index === activeIndex ? "show" : ""}`}
                        aria-labelledby={`heading${index + 1}`}
                        data-bs-parent="#accordionExample"
                    >
                        <div className="accordion-body">{section.content}</div>
                    </div>
                </div>
            ))}
        </div>
    );
}

export default DanzaPacchettiMedia;
