import React from "react";
import "../../styles/Accordion.css"
import TabellaNLOrarioCompleto from "./TabellaNLOrarioCompleto";
import TabellaNLOrarioRidotto from "./TabellaNLOrarioRidotto";

function NuotoLiberoAccordion() {
    return (
        <div className="accordion d-flex flex-column m-auto" id="accordionFlushExample">
            <div className="accordion-item">
                <h2 className="accordion-header d-flex flex-row" id="headingOne"
                    style={{justifyContent: "center", textAlign: "center"}}>
                    <button className="accordion-button" type="button" data-bs-toggle="collapse"
                            data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                        ORARIO PIENO
                    </button>
                </h2>
                <div id="collapseOne" className="accordion-collapse collapse show" aria-labelledby="headingOne"
                     data-bs-parent="#accordionFlushExample">
                    <div className="accordion-body">
                        <TabellaNLOrarioCompleto/>
                        <hr/>
                        <p className="text-center" style={{marginTop: "2em"}}>
                            <strong>3 mesi</strong> 300 €<br/>
                            <strong>6 mesi</strong> 492 €<br/>
                            <strong>9 mesi</strong> 720 €<br/>
                        </p>
                    </div>
                </div>
            </div>
            <div className="accordion-item">
                <h2 className="accordion-header" id="flush-headingTwo">
                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                            data-bs-target="#flush-collapseTwo" aria-expanded="false" aria-controls="flush-collapseTwo">
                        ORARIO RIDOTTO
                    </button>
                </h2>
                <div id="flush-collapseTwo" className="accordion-collapse collapse" aria-labelledby="flush-headingTwo"
                     data-bs-parent="#accordionFlushExample">
                    <div className="accordion-body">
                        <TabellaNLOrarioRidotto/>
                        <hr/>
                        <p className="text-center" style={{marginTop: "2em"}}>
                            <strong>3 mesi</strong> 252 €<br/>
                            <strong>6 mesi</strong> 444 €<br/>
                            <strong>9 mesi</strong> 594 €<br/>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default NuotoLiberoAccordion