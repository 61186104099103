import React from "react";
import TitoloColore from "../commons/TitoloColore";
import ScrollToTopOnMount from "../components/ScrollToTopOnMount";
import calendario from "../images/Calendario Cigno 20232024.jpg"
import Space from "../commons/Space";
import TitoloSezioni from "../commons/TitoloSezioni";


function Calendario() {
    return(
        <div>
            <ScrollToTopOnMount />
            <TitoloColore title={"Calendario"} backgroundColor={"#003F83"} />
            <div className="text-center d-flex flex-column align-items-center">
                <Space />
                <TitoloSezioni title={"Calendario attività"} />
                <div style={{display: "flex", flexDirection: "column", width: "60%"}}>
                <a style={{alignSelf: "end"}} href={calendario} download>
                    <i style={{fontSize: "2em", color: "grey", marginRight: "0.5em"}} className="bi bi-download"></i>
                </a>
                <img src={calendario} style={{width: "100%"}}></img>
                </div>
            </div>
            <Space />
        </div>
    )
}

export default Calendario