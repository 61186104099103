import React from "react";
import "../../styles/Accordion.css"
import TabellaFitnessMattino from "./TabellaFitnessMattino";
import TabellaFitnessIntervallo from "./TabellaFitnessIntervallo";
import TabellaFitnessSera from "./TabellaFitnessSera";
import FitnessLegenda from "./FitnessLegenda";


function FitnessAccordion() {
    return(
        <div className="accordion d-flex flex-column m-auto" id="accordionFlushExample">
            <div className="accordion-item">
                <h2 className="accordion-header d-flex flex-row" id="flush-headingOne" style={{ justifyContent: "center", textAlign: "center" }}>
                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-expanded="false" aria-controls="flush-collapseOne">
                        MATTINO
                    </button>
                </h2>
                <div id="flush-collapseOne" className="accordion-collapse collapse" aria-labelledby="flush-headingOne" data-bs-parent="#accordionFlushExample">
                    <div className="accordion-body">
                        <TabellaFitnessMattino />
                    </div>
                </div>
            </div>
            <div className="accordion-item">
                <h2 className="accordion-header" id="flush-headingTwo">
                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTwo" aria-expanded="false" aria-controls="flush-collapseTwo">
                        INTERVALLO
                    </button>
                </h2>
                <div id="flush-collapseTwo" className="accordion-collapse collapse" aria-labelledby="flush-headingTwo" data-bs-parent="#accordionFlushExample">
                    <div className="accordion-body">
                        <TabellaFitnessIntervallo />
                    </div>
                </div>
            </div>
            <div className="accordion-item">
                <h2 className="accordion-header" id="flush-headingThree">
                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseThree" aria-expanded="false" aria-controls="flush-collapseThree">
                        SERA
                    </button>
                </h2>
                <div id="flush-collapseThree" className="accordion-collapse collapse" aria-labelledby="flush-headingThree" data-bs-parent="#accordionFlushExample">
                    <div className="accordion-body">
                        <TabellaFitnessSera />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default FitnessAccordion