import React from "react";

function PrezziAdulti() {
    return(
        <div>
            <div className="container text-center" style={{marginTop: "2em"}}>
                <div className="row align-items-start">
                    <div className="col">
                        <h6 className="">
                            <span className="" style={{ backgroundColor: "#F9DCC4", marginRight: "6px", fontSize: "20px" }}>MATTINO </span><br/>
                            <span className="" style={{ fontSize: "20px" }}>E</span><br/>
                            <span style={{ backgroundColor: "#FAD1E0", marginLeft: "6px", fontSize: "20px" }}>INTERVALLO-POMERIGGIO</span>
                        </h6>
                        <div style={{fontFamily: "Hammersmith One", fontSize: "20px"}}>MONOSETTIMANALE</div>
                        <strong>3 mesi</strong> (12 lezioni) 204 € <div className="d-block d-sm-none"/>+ iscrizione 10 €<br/>
                        <strong>6 mesi</strong> (24 lezioni) 312 € <div className="d-block d-sm-none"/>+ iscrizione 20 €<br/>
                        <strong>9 mesi</strong> (36 lezioni) 396 € <div className="d-block d-sm-none"/>+ iscrizione 30 €<br/>
                        <br/>
                        <div style={{fontFamily: "Hammersmith One", fontSize: "20px"}}>BISETTIMANALE</div>
                        <strong>3 mesi</strong> (24 lezioni) 252 € <div className="d-block d-sm-none"/>+ iscrizione 10 €<br/>
                        <strong>6 mesi</strong> (48 lezioni) 372 € <div className="d-block d-sm-none"/>+ iscrizione 20 €<br/>
                        <strong>9 mesi</strong> (72 lezioni) 492 € <div className="d-block d-sm-none"/>+ iscrizione 30 €<br/>
                    </div>
                    <div className="col align-self-center">
                        <br/>
                        <h6 className="">
                            <span style={{ backgroundColor: "#D6E3FC", marginRight: "6px", fontSize: "20px" }}>SERA </span>
                        </h6><br/>
                        <div style={{fontFamily: "Hammersmith One", fontSize: "20px"}}>MONOSETTIMANALE</div>

                        <strong>3 mesi</strong> (12 lezioni) 204 € <div className="d-block d-sm-none"/>+ iscrizione 10 €<br/>
                        <strong>6 mesi</strong> (24 lezioni) 312 € <div className="d-block d-sm-none"/>+ iscrizione 20 €<br/>
                        <strong>9 mesi</strong> (36 lezioni) 396 € <div className="d-block d-sm-none"/>+ iscrizione 30 €<br/>
                        <br/>
                        <div style={{fontFamily: "Hammersmith One", fontSize: "20px"}}>BISETTIMANALE</div>
                        <strong>3 mesi</strong> (12 lezioni) 264 € + iscrizione 10 €<br/>
                        <strong>6 mesi</strong> (24 lezioni) 468 € + iscrizione 20 €<br/>
                        <strong>9 mesi</strong> (36 lezioni) 588 € + iscrizione 30 €

                    </div>
                </div>
            </div>
        </div>
    )
}

export default PrezziAdulti