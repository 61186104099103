import React from 'react';

interface LegendProps {
    color1: string;
    color2?: string;
    label1: string;
    label2?: string;
}

const Legend: React.FC<LegendProps> = ({ color1, color2, label1, label2 }) => {
    return (
        <div style={{ display: 'flex', flexDirection: "row", alignItems: "stretch", fontFamily: 'Arial, sans-serif' }}>
            <div style={{display:"flex", flexDirection: "column"}}>
                <div style={{ width: '20px', height: '20px', borderRadius: '50%', marginRight: '8px', marginBottom:"8px", backgroundColor: color1 }}></div>
                {color2 && <div style={{ width: '20px', height: '20px', borderRadius: '50%', marginRight: '8px', backgroundColor: color2 }}></div>}
            </div>
            <div style={{display:"flex", flexDirection: "column"}}>
                <div style={{ marginLeft: '16px', fontSize: '16px'}}>{label1}</div>
                {label2 && <div style={{ marginLeft: '16px', fontSize: '16px' }}>{label2}</div>}
            </div>
        </div>
    );
};

export default Legend;
