import React, {createContext} from 'react';
import logo from './logo.svg';
import './App.css';
import 'bootstrap-icons/font/bootstrap-icons.css'
import {BrowserRouter, NavLink, Outlet, Route, Routes, useParams} from "react-router-dom";
import Bar from "./components/Bar";
import Home from "./pages/Home";
import NuotoCorsi from "./pages/Nuoto_pages/NuotoCorsi";
import Nuoto from "./pages/Nuoto_pages/Nuoto";
import NuotoLibero from "./pages/Nuoto_pages/NuotoLibero";
import LezioniIndividuali from "./pages/Nuoto_pages/LezioniIndividuali";
import AcquaFitness from "./pages/Nuoto_pages/AcquaFitness";
import RiabilitazioneMotoria from "./pages/Nuoto_pages/RiabilitazioneMotoria";
import Palestra from "./pages/Palestra";
import Fitness from "./pages/Fitness";
import Runnering from "./pages/Runnering";
import Danza from "./pages/Danza";
import Spa from "./pages/Spa";
import Multitessere from "./pages/Multitessere";
import News from "./pages/News";
import ChiSiamo from "./pages/ChiSiamo";
import Contatti from "./pages/Contatti";
import Calendario from "./pages/Calendario";
import Regolamento from "./pages/Regolamento";
import Osteopata from "./pages/Osteopata";
import VisiteMediche from "./pages/VisiteMediche";
import Abbigliamento from "./pages/Abbigliamento";
import Cafe from "./pages/Cafe";
import Footer from "./pages/Footer";
import "./globalStyles.css"
import SireneNews from "./pages/news_pages/SireneNews";
import NewsHome from "./pages/NewsHome";
import ZumbaNews from "./pages/news_pages/ZumbaNews";
import BlackFridayNews from "./pages/news_pages/BlackFridayNews";
import ScrollToTopOnMount from "./components/ScrollToTopOnMount";
import Master from "./pages/Nuoto_pages/Master";


function Main() {
    return(
        <div>
            <Bar />
            <div style={{height: "90px", width: "100%"}}></div>
            <Outlet />
            <div style={{width: "100%", backgroundColor: "#032041"}}>
                <PageFooter />
            </div>
        </div>
    )
}

// <Footer /> dopo Outlet

function PageHome() {
    return (
        <div>
            <Home/>
        </div>
    );
}

function PageNuoto() {
    return(
        <div>
            <Nuoto />
        </div>
    )
}
function PageNuotolibero() {
    return(
        <div>
            <NuotoLibero />
        </div>
    )
}
function PageCorsinuoto() {
    return(
        <div>
            <NuotoCorsi />
        </div>
    )
}
function PageLezionindividuali() {
    return(
        <div>
            <LezioniIndividuali />
        </div>
    )
}
function PageAcquafitness() {
    return(
        <div>
            <AcquaFitness />
        </div>
    )
}
function PageRiabilitazionemotoria() {
    return(
        <div>
            <RiabilitazioneMotoria />
        </div>
    )
}

function PageMaster() {
    return(
        <div>
            <Master />
        </div>
    )
}

function PagePalestra() {
    return(
        <div>
            <Palestra />
        </div>
    )
}

function PageFitness() {
    return(
        <div>
            <Fitness />
        </div>
    )
}

function PageDanza() {
    return(
        <div>
            <Danza />
        </div>
    )
}

function PageSpa() {
    return(
        <div>
            <Spa />
        </div>
    )
}

function PageRunnering() {
    return(
        <div>
            <Runnering />
        </div>
    )
}

function PageMultitessere() {
    return(
        <div>
            <Multitessere />
        </div>
    )
}

function PageNews() {
    return(
        <div>
            <News />
            <Outlet />
        </div>
    )
}

function PageOrari() {
    return(
        <div>

        </div>
    )
}

function PageChiSiamo() {
    return(
        <div>
            <ChiSiamo />
        </div>
    )
}

function PageContatti() {
    return(
        <div>
            <Contatti />
        </div>
    )
}

function PageCalendario() {
    return(
        <div>
            <Calendario />
        </div>
    )
}

function PageRegolamento() {
    return(
        <div>
            <Regolamento />
        </div>
    )
}

function PageOsteopata() {
    return(
        <div>
            <Osteopata />
        </div>
    )
}

function PageVisitemediche() {
    return(
        <div>
            <VisiteMediche />
        </div>
    )
}

function PageAbbigliamento() {
    return(
        <div>
            <Abbigliamento />
        </div>
    )
}

function PageCafe() {
    return(
        <div>
            <Cafe />
        </div>
    )
}

function PageFooter() {
    return (
        <Footer />
    );
}

function PageNewsHome() {
    return(
        <div>
            <NewsHome />
        </div>
    )
}





function App() {
  return (
      <div >
          <BrowserRouter>
                  <Routes>
                      <Route path="/" element={<Main/>}>
                          <Route path={"/"} element={<PageHome/>}></Route>
                          <Route path={"/chisiamo"} element={<PageChiSiamo/>}></Route>
                          <Route path={"/contatti"} element={<PageContatti/>}></Route>
                          <Route path={"/calendario"} element={<PageCalendario/>}></Route>
                          <Route path={"/regolamento"} element={<PageRegolamento/>}></Route>
                          <Route path={"/osteopata"} element={<PageOsteopata/>}></Route>
                          <Route path={"/visitemediche"} element={<PageVisitemediche/>}></Route>
                          <Route path={"/abbigliamento"} element={<PageAbbigliamento/>}></Route>
                          <Route path={"/ilcignocafé"} element={<PageCafe/>}></Route>
                          <Route path={"/piscina"} element={<PageNuoto/>}></Route>
                          <Route path={"/nuotolibero"} element={<PageNuotolibero/>}></Route>
                          <Route path={"/corsinuoto"} element={<PageCorsinuoto/>}></Route>
                          <Route path={"/lezionindividuali"} element={<PageLezionindividuali/>}></Route>
                          <Route path={"/acquafitness"} element={<PageAcquafitness/>}></Route>
                          <Route path={"/riabilitazionemotoria"} element={<PageRiabilitazionemotoria/>}></Route>
                          <Route path={"/master"} element={<PageMaster/>}></Route>
                          <Route path={"/palestra"} element={<PagePalestra/>}></Route>
                          <Route path={"/fitness"} element={<PageFitness/>}></Route>
                          <Route path={"/danza"} element={<PageDanza/>}></Route>
                          <Route path={"/spa"} element={<PageSpa/>}></Route>
                          <Route path={"/runnering"} element={<PageRunnering/>}></Route>
                          <Route path={"/multitessere"} element={<PageMultitessere/>}></Route>
                          <Route path="/news" element={<PageNews />}>
                              <Route path={"/news"} element={<PageNewsHome />}></Route>
                              <Route path={"/news/sirene"} element={<SireneNews />}></Route>
                              <Route path={"/news/zumba"} element={<ZumbaNews />}></Route>
                              <Route path={"/news/blackFriday"} element={<BlackFridayNews />}></Route>
                          </Route>
                      </Route>
                  </Routes>
          </BrowserRouter>
      </div>
     );
}
export default App;
