import React, { useState, useEffect } from "react";
import "../../styles/MultitessereSezioni.css";

function DanzaPacchettixxl() {
    const sections = [
        {
            id: "collapseONE",
            label: "EXPERIENCE",
            content: <div>
                <figure className="text-center">
                    <blockquote className="blockquote">
                        <p>
                            <h2 className="d-flex flex-row justify-content-center m-auto" style={{borderBottom: "solid", width: "10em", fontFamily: "Hammersmith One"}}>EXPERIENCE</h2>
                            <br/>
                            <strong> 12 mesi </strong> 620 € + iscrizione 30 €
                        </p>
                    </blockquote>
                </figure>
            </div>,
        },
        {
            id: "collapseTWO",
            label: "EXPERIENCE PLUS",
            content: <div>
                <figure className="text-center">
                    <blockquote className="blockquote">
                        <p>
                            <h2 className="d-flex flex-row justify-content-center m-auto" style={{borderBottom: "solid", width: "10em", fontFamily: "Hammersmith One"}}>EXPERIENCE PLUS</h2>
                            <br/>
                            <strong> 12 mesi </strong> 670 € + iscrizione 30 €
                        </p>
                    </blockquote>
                </figure>
            </div>,
        }
    ];
    const [activePanel, setActivePanel] = useState<string | null>(
        sections.length > 0 ? sections[0].id : null
    );

    const handleButtonClick = (panelId: string) => {
        // Chiudi il pannello attivo se presente
        if (activePanel === panelId) {
            setActivePanel(null);
        } else {
            // Altrimenti, apri il nuovo pannello cliccato
            setActivePanel(panelId);
        }
    };



    return (
        <div className="container-fluid my-container d-flex flex-column m-auto ">
            <div
                className="d-flex flex-wrap p-flex"
                style={{
                    position: "relative",
                    zIndex: 1,
                    marginBottom: 0,
                    marginLeft: "8px",
                }}
            >
                {sections.map((section) => (
                    <button
                        key={section.id}
                        className={`btn btn-outline-secondary my-btn ${activePanel === section.id ? "active" : ""}`}
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target={`#${section.id}`}
                        aria-expanded={activePanel === section.id ? "true" : "false"}
                        aria-controls={section.id}
                        onClick={() => handleButtonClick(section.id)}
                    >
                        {section.label}
                    </button>
                ))}

            </div>
            <div>
                {sections.map((section) => (
                    <div
                        key={section.id}
                        className={`collapse ${activePanel === section.id ? "show" : ""}`}
                        id={section.id}
                    >
                        <div className="card card-body my-card">{section.content}</div>
                    </div>
                ))}
            </div>
        </div>
    );
}

export default DanzaPacchettixxl;
