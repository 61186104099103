import React from "react";
import logo from "../images/home/logoCigno_scritta_white.png"
import {Link, NavLink, useNavigate} from "react-router-dom";


function Footer() {
    const navigate = useNavigate()
    return(
        <div className="container text-white" style={{backgroundColor: "#032041", }}>
            <footer className="py-5">
                <div className="row">
                    <div className="d-flex col-3 mb-4" style={{margin: "0 0 0 0"}}>
                    <img className="" src={logo} style={{height: "10em", paddingLeft: 0}}></img>
                    </div>
                    <div className="col-md-2 mb-3" style={{margin: "0 3em 0 0"}}>
                        <div>
                            <h3 className="mb-2" style={{fontFamily: "Hammersmith One"}}>IL CIGNO</h3>
                            <p>
                                Via Fiorani, 74
                                <br/>
                                20099
                                <br/>
                                Sesto San Giovanni (MI)
                            </p>
                            <p>
                                02 2401 006 <br/>
                                <a className="text-white-50" title="mailto:info@ilcignosport.it" href="mailto:info@ilcignosport.it">info@ilcignosport.it</a>
                            </p>
                        </div>
                    </div>
                    <div className="col-6 col-md-2 mb-3">
                        <h5>Attività</h5>
                        <ul className="nav flex-column">
                            <li className="nav-item mb-2"><NavLink to="/piscina"  className="nav-link p-0 text-white-50">Piscina</NavLink></li>
                            <li className="nav-item mb-2"><NavLink to="/palestra" className="nav-link p-0 text-white-50">Palestra</NavLink></li>
                            <li className="nav-item mb-2"><NavLink to="/fitness" className="nav-link p-0 text-white-50">Fitness</NavLink></li>
                            <li className="nav-item mb-2"><NavLink to="/danza" className="nav-link p-0 text-white-50">Danza</NavLink></li>
                            <li className="nav-item mb-2"><NavLink to="/runnering" className="nav-link p-0 text-white-50">Runnering</NavLink></li>
                            <li className="nav-item mb-2"><NavLink to="/spa" className="nav-link p-0 text-white-50">Spa</NavLink></li>
                        </ul>
                    </div>

                    <div className="col-6 col-md-2 mb-3">
                        <h5>Link utili</h5>
                        <ul className="nav flex-column">
                            <li className="nav-item mb-2"><NavLink to="/" className="nav-link p-0 text-white-50">Home</NavLink></li>
                            <li className="nav-item mb-2"><NavLink to="/chisiamo" className="nav-link p-0 text-white-50">Chi siamo</NavLink></li>
                            <li className="nav-item mb-2"><NavLink to="/contatti" className="nav-link p-0 text-white-50">Contatti</NavLink></li>
                            <li className="nav-item mb-2"><NavLink to="/calendario" className="nav-link p-0 text-white-50">Calendario</NavLink></li>
                            <li className="nav-item mb-2"><NavLink to="/regolamento" className="nav-link p-0 text-white-50">Regolamento</NavLink></li>
                            <li className="nav-item mb-2"><NavLink to="/news" className="nav-link p-0 text-white-50">News</NavLink></li>
                        </ul>
                    </div>

                    <div className="col-6 col-md-2 mb-3" >
                        <h5>Social media</h5>
                        <ul className="nav flex-row">
                            <li className="nav-item mb-2 ">
                                <Link to="https://www.instagram.com/centrosportivoilcigno/?hl=it" className="nav-link p-0">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="white" className="bi bi-instagram" viewBox="0 0 16 16">
                                        <path d="M8 0C5.829 0 5.556.01 4.703.048 3.85.088 3.269.222 2.76.42a3.917 3.917 0 0 0-1.417.923A3.927 3.927 0 0 0 .42 2.76C.222 3.268.087 3.85.048 4.7.01 5.555 0 5.827 0 8.001c0 2.172.01 2.444.048 3.297.04.852.174 1.433.372 1.942.205.526.478.972.923 1.417.444.445.89.719 1.416.923.51.198 1.09.333 1.942.372C5.555 15.99 5.827 16 8 16s2.444-.01 3.298-.048c.851-.04 1.434-.174 1.943-.372a3.916 3.916 0 0 0 1.416-.923c.445-.445.718-.891.923-1.417.197-.509.332-1.09.372-1.942C15.99 10.445 16 10.173 16 8s-.01-2.445-.048-3.299c-.04-.851-.175-1.433-.372-1.941a3.926 3.926 0 0 0-.923-1.417A3.911 3.911 0 0 0 13.24.42c-.51-.198-1.092-.333-1.943-.372C10.443.01 10.172 0 7.998 0h.003zm-.717 1.442h.718c2.136 0 2.389.007 3.232.046.78.035 1.204.166 1.486.275.373.145.64.319.92.599.28.28.453.546.598.92.11.281.24.705.275 1.485.039.843.047 1.096.047 3.231s-.008 2.389-.047 3.232c-.035.78-.166 1.203-.275 1.485a2.47 2.47 0 0 1-.599.919c-.28.28-.546.453-.92.598-.28.11-.704.24-1.485.276-.843.038-1.096.047-3.232.047s-2.39-.009-3.233-.047c-.78-.036-1.203-.166-1.485-.276a2.478 2.478 0 0 1-.92-.598 2.48 2.48 0 0 1-.6-.92c-.109-.281-.24-.705-.275-1.485-.038-.843-.046-1.096-.046-3.233 0-2.136.008-2.388.046-3.231.036-.78.166-1.204.276-1.486.145-.373.319-.64.599-.92.28-.28.546-.453.92-.598.282-.11.705-.24 1.485-.276.738-.034 1.024-.044 2.515-.045v.002zm4.988 1.328a.96.96 0 1 0 0 1.92.96.96 0 0 0 0-1.92zm-4.27 1.122a4.109 4.109 0 1 0 0 8.217 4.109 4.109 0 0 0 0-8.217zm0 1.441a2.667 2.667 0 1 1 0 5.334 2.667 2.667 0 0 1 0-5.334"/>
                                    </svg>
                                </Link>
                            </li>
                            <li className="nav-item mb-2 ">
                                <Link to="https://www.facebook.com/ilcignosport.it/?locale=it_IT" className="nav-link p-0">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="white" className="bi bi-facebook" viewBox="0 0 16 16">
                                        <path d="M16 8.049c0-4.446-3.582-8.05-8-8.05C3.58 0-.002 3.603-.002 8.05c0 4.017 2.926 7.347 6.75 7.951v-5.625h-2.03V8.05H6.75V6.275c0-2.017 1.195-3.131 3.022-3.131.876 0 1.791.157 1.791.157v1.98h-1.009c-.993 0-1.303.621-1.303 1.258v1.51h2.218l-.354 2.326H9.25V16c3.824-.604 6.75-3.934 6.75-7.951"/>
                                    </svg>
                                </Link>
                            </li>
                        </ul>
                    </div>

                </div>

                <div className="d-flex flex-column flex-sm-row justify-content-between py-4 my-4 border-top">
                    <p>&copy; 2023 Il Cigno. All rights reserved.</p>
                    <ul className="list-unstyled d-flex">
                        <li className="ms-3"><a className="link-dark" href="#"><svg className="bi" width="24" height="24"><use href="#twitter"/></svg></a></li>
                        <li className="ms-3"><a className="link-dark" href="#"><svg className="bi" width="24" height="24"><use href="#instagram"/></svg></a></li>
                        <li className="ms-3"><a className="link-dark" href="#"><svg className="bi" width="24" height="24"><use href="#facebook"/></svg></a></li>
                    </ul>
                </div>
            </footer>
        </div>
    )
}

export default Footer