import "../styles/CaroselloStandard.css"

import React from "react";

interface CaroselloStandardProps {
    foto1: string
    foto2: string
    foto3: string
    foto4?: string
    foto5?: string
    foto6?: string
    foto7?: string
    foto8?: string
}

function CaroselloStandard ({foto1, foto2, foto3, foto4, foto5, foto6, foto7, foto8} : CaroselloStandardProps) {
    return(
        <div id="carouselExampleCaptions" className="carousel my-carousel slide m-auto" data-bs-ride="false" style={{margin: "1em", borderRadius: "1em"}}>
            <div className="carousel-indicators my-carousel">
                <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1"></button>
                <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="1" aria-label="Slide 2"></button>
                <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="2" aria-label="Slide 3"></button>
                {
                    foto4 && <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="3" aria-label="Slide 4"></button>
                }
                {
                    foto5 && <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="4" aria-label="Slide 5"></button>
                }
                {
                    foto6 && <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="5" aria-label="Slide 6"></button>
                }
                {
                    foto7 && <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="6" aria-label="Slide 7"></button>
                }
                {
                    foto8 && <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="7" aria-label="Slide 8"></button>
                }
            </div>
            <div className="carousel-inner my-carousel" style={{borderRadius: "1em"}}>
                <div className="carousel-item my-carousel active">
                    <img src={foto1} className="d-block w-100" alt="..."/>
                    <div className="carousel-caption d-none d-md-block">
                        <h5></h5>
                        <p></p>
                    </div>
                </div>
                <div className="carousel-item my-carousel">
                    <img src={foto2} className="d-block w-100" alt="..."/>
                    <div className="carousel-caption d-none d-md-block">
                        <h5></h5>
                        <p></p>
                    </div>
                </div>
                <div className="carousel-item my-carousel">
                    <img src={foto3} className="d-block w-100" alt="..."/>
                    <div className="carousel-caption d-none d-md-block">
                        <h5></h5>
                        <p></p>
                    </div>
                </div>
                {
                    foto4 &&
                    <div className="carousel-item my-carousel">
                        <img src={foto4} className="d-block w-100" alt="..."/>
                        <div className="carousel-caption d-none d-md-block">
                            <h5></h5>
                            <p></p>
                        </div>
                    </div>
                }
                {
                    foto5 &&
                    <div className="carousel-item my-carousel">
                        <img src={foto5} className="d-block w-100" alt="..."/>
                        <div className="carousel-caption d-none d-md-block">
                            <h5></h5>
                            <p></p>
                        </div>
                    </div>
                }
                {
                    foto6 &&
                    <div className="carousel-item my-carousel">
                        <img src={foto6} className="d-block w-100" alt="..."/>
                        <div className="carousel-caption d-none d-md-block">
                            <h5></h5>
                            <p></p>
                        </div>
                    </div>
                }
                {
                    foto7 &&
                    <div className="carousel-item my-carousel">
                        <img src={foto7} className="d-block w-100" alt="..."/>
                        <div className="carousel-caption d-none d-md-block">
                            <h5></h5>
                            <p></p>
                        </div>
                    </div>
                }
                {
                    foto8 &&
                    <div className="carousel-item my-carousel">
                        <img src={foto8} className="d-block w-100" alt="..."/>
                        <div className="carousel-caption d-none d-md-block">
                            <h5></h5>
                            <p></p>
                        </div>
                    </div>
                }
            <button className="carousel-control-prev my-carousel" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="prev">
                <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                <span className="visually-hidden">Previous</span>
            </button>
            <button className="carousel-control-next my-carousel" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="next">
                <span className="carousel-control-next-icon" aria-hidden="true"></span>
                <span className="visually-hidden">Next</span>
            </button>
        </div>
        </div>
    )
}

export default CaroselloStandard