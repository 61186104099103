import React from "react";
import CardNews from "../components/CardNews";
import sirene from "../images/News/Sirene.jpg";
import zumba from "../images/News/Zumba.jpg"
import blackFriday from "../images/News/Black_friday.jpg"
import ScrollToTopOnMount from "../components/ScrollToTopOnMount";
import Space from "../commons/Space";
import TitoloSezioni from "../commons/TitoloSezioni";
import {Link} from "react-router-dom";
import insta from "../icone/icons8-instagram-48.png";
import facebook from "../icone/icons8-facebook-48.png";

function NewsHome () {
    return(
        <div>
        <div className="card-container flex-row flex-wrap justify-content-center">
            <ScrollToTopOnMount />
            <CardNews foto={zumba} titolo={"Corso di Zumba"} descrizione={""} data={"01.12.2023"} indirizzo={"./zumba"} settore1={"fitness"} posizioneFoto={"top"}/>
            <CardNews foto={blackFriday} titolo={"Black Friday"} descrizione={"Sconto 30% su abbigliamento e palestra"} data={"18.11.2023"} indirizzo={"./blackFriday"} settore1={"abbigliamento"} settore2={"palestra"} posizioneFoto={"top"} />
            <CardNews foto={sirene} titolo={"Mermaid Experience"} descrizione={""} data={"15.11.2023"} indirizzo={"./sirene"} settore1={"piscina"} posizioneFoto={"center"}/>
            <Space />

        </div>
            <Space />
            <TitoloSezioni title={"Social"} />
            <figure className="text-center" style={{marginTop: "2em"}}>
                <blockquote className="blockquote">
                    <h2>Resta sempre aggiornato</h2>
                    <h3>Seguici su Instagram e Facebook</h3>
                    <p style={{marginTop: "2em"}}>
                        <Link to="https://www.instagram.com/centrosportivoilcigno/?hl=it">
                            <img src={insta}></img>
                        </Link>
                        <Link to="https://www.facebook.com/ilcignosport.it/?locale=it_IT">
                            <img src={facebook}></img>
                        </Link>
                    </p>
                    <br/>
                </blockquote>
            </figure>
            <Space />
        </div>
    )
}
export default NewsHome