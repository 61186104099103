import React from "react";
import foto1 from "../../images/home/IMG_8672-2.jpg"
import foto4 from "../../images/home/Nuoto.jpeg"
import foto2 from "../../images/Danza/IMG_1745.jpg"
import foto3 from "../../images/home/IMG_9286-4.jpg"
import "./CaroselloHome.css"


interface CaroselloHomeProps {}

function CaroselloHome (_:CaroselloHomeProps) {
    return(
        <div>
            <div id="carouselExampleCaptions" className="carousel my-carousel-home slide" data-bs-ride="false">
                <div className="carousel-indicators">
                    <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1"></button>
                    <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="1" aria-label="Slide 2"></button>
                    <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="2" aria-label="Slide 3"></button>
                </div>
                <div className="carousel-inner">
                    <div className="carousel-item my-carousel-home active">
                        <section
                            className="titolo-section-home"
                            style={{ backgroundImage: `url(${foto1})`, width: "100%" }}
                        >
                        <div className="overlay"></div>
                        <h1 className="carousel-caption my-carousel-title">
                            CENTRO SPORTIVO <br/> IL CIGNO
                        </h1>
                        </section>
                    </div>
                    <div className="carousel-item my-carousel-home">
                        <img src={foto2} className="d-block w-100" alt="..."/>
                            <div className="carousel-caption d-none d-md-block">
                                <h5></h5>
                                <p></p>
                            </div>
                    </div>
                    <div className="carousel-item my-carousel-home">
                        <img src={foto3} className="d-block w-100" alt="..."/>
                            <div className="carousel-caption d-none d-md-block">
                                <h5></h5>
                                <p></p>
                            </div>
                    </div>
                </div>
                <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="prev">
                    <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                    <span className="visually-hidden">Previous</span>
                </button>
                <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="next">
                    <span className="carousel-control-next-icon" aria-hidden="true"></span>
                    <span className="visually-hidden">Next</span>
                </button>
            </div>
        </div>
    )
}

export default CaroselloHome