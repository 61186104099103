import React from "react";
import TitoloColore from "../commons/TitoloColore";
import ScrollToTopOnMount from "../components/ScrollToTopOnMount";
import Space from "../commons/Space";
import SpaceL from "../commons/SpaceL";
import CaroselloStandard from "../commons/CaroselloStandard";
import foto1 from "../images/Cafe/IMG_9274.jpg"
import foto2 from "../images/Cafe/IMG_9279.jpg"
import foto3 from "../images/Cafe/IMG_9276.jpg"
import foto4 from "../images/Cafe/IMG_9282.jpg"
import piatto1 from "../images/Cafe/collage_piatti.jpg"




function Cafe() {
    return(
        <div>
            <ScrollToTopOnMount />
            <TitoloColore title={"Il Cigno Café"} backgroundColor={"#003F83"} />
            <Space />
            <div className="table-container">
                <table className="table table-bordered m-auto" style={{width: "20em"}}>
                    <colgroup>
                        <col style={{ width: "14.287%", minWidth: "50px"}} />
                        <col style={{ width: "14.287%", minWidth: "50px" }} />
                    </colgroup>
                    <thead>
                    <tr>
                        <th className="header-cell" scope="col">Giorno</th>
                        <th className="header-cell" scope="col">Orario</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <td className="data-cell">dal lunedì al venerdì</td>
                        <td className="data-cell">9-19.30</td>
                    </tr>
                    <tr>
                        <td className="data-cell">sabato</td>
                        <td className="data-cell">9-15</td>
                    </tr>
                    <tr>
                        <td className="data-cell">domenica</td>
                        <td className="data-cell">9-12.30</td>
                    </tr>
                    </tbody>
                </table>
                <SpaceL />
            </div>
            <SpaceL />
            <CaroselloStandard foto1={foto1} foto2={foto2} foto3={foto3} foto4={piatto1} />
            <Space />
        </div>
    )
}

export default Cafe