import React from "react";
import Space from "../../commons/Space";
import ScrollToTopOnMount from "../../components/ScrollToTopOnMount";
import Titolo from "../../commons/Titolo";
import foto from "../../images/Nuoto/Master/DSCF9116.jpg";
import FrecciaIndietro from "../../commons/FrecciaIndietro";
import TitoloSezioni from "../../commons/TitoloSezioni";
import dicembre from "../../images/Nuoto/Master/Aggiornamento Dicembre 2023_page-0001.jpg";
import gennaio1 from "../../images/Nuoto/Master/Aggiornamento Gennaio 2024_page-0001.jpg";
import gennaio2 from "../../images/Nuoto/Master/Aggiornamento Gennaio 2024_page-0002.jpg";
import 'bs5-lightbox';
import SpaceL from "../../commons/SpaceL";
import { Link } from "react-router-dom"; // Importa il componente Link da react-router-dom
import "../../styles/Master.css";

function Master() {
    return (
        <div>
            <ScrollToTopOnMount />
            <Titolo backgroundImage={foto} title={"Master"} />
            <FrecciaIndietro indirizzo={"/piscina"} />
            <TitoloSezioni title={"Risultati gare"} />
            <SpaceL />
            <div className="d-flex flex-row container" style={{ overflowX: "auto" }}>
                <img src={gennaio2} className="img-fluid img-thumbnail" alt="..." />
                <img src={gennaio1} className="img-fluid img-thumbnail" alt="..." />
                <img src={dicembre} className="img-fluid img-thumbnail" alt="..." />
            </div>
            <SpaceL />
            <div className="text-center">
                <a href="https://www.federnuoto.it/home/master/circuito-supermaster/eventi-circuito-supermaster.html" target="_blank" rel="noopener noreferrer" className="text-center" style={{ fontSize: "22px", padding: "1em", fontFamily: "Hammersmith One", color: "#032041" }}> Prossime gare </a>
            </div>
            <Space />
        </div>
    )
}

export default Master;
