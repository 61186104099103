import React from "react";
import NuotoCorsiSezioniDue from "../../components/corsi_nuoto/NuotoCorsiSezioni";
import CaroselloStandard from "../../commons/CaroselloStandard";
import corsi from "../../images/Nuoto/corsiNuoto/IMG_8526-3.jpg"
import foto1 from "../../images/Nuoto/corsiNuoto/IMG_8604.jpg"
import foto2 from "../../images/Nuoto/corsiNuoto/IMG_8552.jpg"
import foto5 from "../../images/Nuoto/corsiNuoto/IMG_8577.jpg";
import foto6 from "../../images/Nuoto/corsiNuoto/IMG_8590.jpg";
import foto7 from "../../images/Nuoto/corsiNuoto/IMG_1747.jpg";
import foto3 from "../../images/Nuoto/corsiNuoto/IMG_7361-3.jpg";
import foto4 from "../../images/Nuoto/corsiNuoto/IMG_1760-2.jpg";
import Titolo from "../../commons/Titolo";
import ScrollToTopOnMount from "../../components/ScrollToTopOnMount";
import {useNavigate} from "react-router-dom";
import Spa from "../Spa";
import Space from "../../commons/Space";
import CosaPortareNuoto from "../../components/corsi_nuoto/CosaPortareNuoto";
import GenitoriAttivi from "../../commons/GenitoriAttivi";
import FrecciaIndietro from "../../commons/FrecciaIndietro";
import SpaceL from "../../commons/SpaceL";




function NuotoCorsi() {
    const navigate = useNavigate()
    return(
        <div>
            <ScrollToTopOnMount />
            <Titolo backgroundImage={corsi} title={"Corsi Nuoto"}/>
            <FrecciaIndietro indirizzo={"/piscina"} />
            <br/>
            <figure className="text-center">
                <blockquote className="blockquote">
                    <p>
                        <h3>Corsi mono o bi settimanali con personale qualificato e brevettato FIN</h3>
                        <h4>modulati per età e preparazione tecnica</h4>
                        <br/>
                        <h4>adatti a chiunque voglia imparare a nuotare o perfezionare gli stili</h4>
                    </p>
                </blockquote>
            </figure>
            <SpaceL />

            <NuotoCorsiSezioniDue />
            <Space />
            <CosaPortareNuoto />
            <Space />
            <GenitoriAttivi />
            <Space />
            <CaroselloStandard foto1={foto1} foto2={foto2} foto3={foto3} foto4={foto4} foto5={foto5} foto6={foto6} foto7={foto7}/>
            <Space />
        </div>
    )
}


export default NuotoCorsi
