import React from "react";
import "../../styles/Accordion.css"
import TabellaDanzaMono60 from "./TabellaDanzaMono60";
import TabellaDanzaMono90 from "./TabellaDanzaMono90";
import TabellaDanzaBi from "./TabellaDanzaBi";



function DanzaAccordion() {
    return(
        <div className="accordion d-flex flex-column m-auto" id="accordionFlushExample">
            <div className="accordion-item">
                <h2 className="accordion-header d-flex flex-row" id="flush-headingOne" style={{ justifyContent: "center", textAlign: "center" }}>
                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-expanded="false" aria-controls="flush-collapseOne">
                        CORSI MONOSETTIMANALI - 60 MINUTI
                    </button>
                </h2>
                <div id="flush-collapseOne" className="accordion-collapse collapse" aria-labelledby="flush-headingOne" data-bs-parent="#accordionFlushExample">
                    <div className="accordion-body">
                        <TabellaDanzaMono60 />
                        <hr/>
                        <p className="text-center" style={{marginTop: "2em"}}>
                            <strong>3 mesi</strong> €160 + iscrizione €10 <br/>
                            <strong>6 mesi</strong> €250 + iscrizione €20 <br/>
                            <strong>9 mesi</strong> €350 + iscrizione €30 <br/>
                        </p>
                    </div>
                </div>
            </div>
            <div className="accordion-item">
                <h2 className="accordion-header" id="flush-headingTwo">
                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTwo" aria-expanded="false" aria-controls="flush-collapseTwo">
                        CORSI MONOSETTIMANALI - 90 MINUTI
                    </button>
                </h2>
                <div id="flush-collapseTwo" className="accordion-collapse collapse" aria-labelledby="flush-headingTwo" data-bs-parent="#accordionFlushExample">
                    <div className="accordion-body">
                        <TabellaDanzaMono90 />
                        <hr/>
                        <p className="text-center" style={{marginTop: "2em"}}>
                            <strong>3 mesi</strong> €180 + iscrizione €10 <br/>
                            <strong>6 mesi</strong> €280 + iscrizione €20 <br/>
                            <strong>9 mesi</strong> €420 + iscrizione €30 <br/>
                        </p>
                    </div>
                </div>
            </div>
            <div className="accordion-item">
                <h2 className="accordion-header" id="flush-headingThree">
                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseThree" aria-expanded="false" aria-controls="flush-collapseThree">
                        CORSI BISETTIMANALI
                    </button>
                </h2>
                <div id="flush-collapseThree" className="accordion-collapse collapse" aria-labelledby="flush-headingThree" data-bs-parent="#accordionFlushExample">
                    <div className="accordion-body">
                        <TabellaDanzaBi />
                        <hr/>
                        <div className="container text-center" style={{marginTop: "2em"}}>
                            <div className="row align-items-start">
                                <div className="col">
                                    <div style={{fontFamily: "Hammersmith One", fontSize: "20px"}}>Un corso bisettimanale</div>
                                    <strong>3 mesi</strong> 230 € <div className="d-block d-sm-none"/>+ iscrizione 10 €<br/>
                                    <strong>6 mesi</strong> 350 € <div className="d-block d-sm-none"/>+ iscrizione 20 €<br/>
                                    <strong>9 mesi</strong> 470 € <div className="d-block d-sm-none"/>+ iscrizione 30 €<br/>
                                </div>
                                <div className="col align-self-center">
                                    <div style={{fontFamily: "Hammersmith One", fontSize: "20px"}}>Due corsi bisettimanali</div>
                                    <strong>3 mesi</strong> 310 € <div className="d-block d-sm-none"/>+ iscrizione 10 €<br/>
                                    <strong>6 mesi</strong> 430 € <div className="d-block d-sm-none"/>+ iscrizione 20 €<br/>
                                    <strong>9 mesi</strong> 550 € <div className="d-block d-sm-none"/>+ iscrizione 30 €<br/>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default DanzaAccordion