import React from "react";

function TabellaFitnessIntervallo() {
    return(
        <div className="table-container">
            <table className="table table-bordered m-auto" style={{width: "90%"}}>
                <thead>
                <tr>
                    <th className="header-cell" scope="col">Lun</th>
                    <th className="header-cell" scope="col">Mar</th>
                    <th className="header-cell" scope="col">Mer</th>
                    <th className="header-cell" scope="col">Gio</th>
                    <th className="header-cell" scope="col">Ven</th>
                </tr>
                </thead>
                <tbody>
                <tr>
                    <td className="data-cell">
                        12:45 - 14:15 <br/> <a href="#infoFitness" style={{color: "black"}}>Pilates + Total Body </a>
                    </td>
                    <td className="data-cell"></td>
                    <td className="data-cell">
                        12:15 - 13:15 <br/> <a href="#infoFitness" style={{color: "black"}}>Hatha Yoga </a>
                    </td>
                    <td className="data-cell">
                        12:45 - 14:15 <br/> <a href="#infoFitness" style={{color: "black"}}>Functional Training + Pilates </a>
                    </td>
                    <td className="data-cell"></td>
                </tr>
                </tbody>
            </table>
        </div>
    )
}

export default TabellaFitnessIntervallo