import React, {useEffect, useState} from "react";
import TitoloColore from "../commons/TitoloColore";
import ScrollToTopOnMount from "../components/ScrollToTopOnMount";
import Space from "../commons/Space";
import ReactPlayer from 'react-player'
import {ReactPhotoSphereViewer} from "react-photo-sphere-viewer";
import foto360 from "../images/home/GS__0722-3.jpg";
import TitoloSezioni from "../commons/TitoloSezioni";
import SpaceL from "../commons/SpaceL";
import fin from "../images/logo/Fin.svg";
import uisp from "../images/logo/Logo_UISP.png";
import libertas from "../images/logo/libertas.jpeg";




function ChiSiamo() {
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };

        window.addEventListener("resize", handleResize);

        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);
    return(
        <div>
            <ScrollToTopOnMount />
            <TitoloColore title={"Chi Siamo"} backgroundColor={"#003F83"} />
            <Space />
            <TitoloSezioni title={"Il nostro centro"} />
            <SpaceL />
            {windowWidth > 900 ? (
                <ReactPlayer config={{ file: {
                        attributes: {
                            controlsList: 'nodownload'
                        }}
                }} style={{margin: "auto"}} url='il_cigno_video.mp4' controls={true} />
                ):
                windowWidth > 650 && windowWidth < 900 ? (
                    <ReactPlayer config={{ file: {
                            attributes: {
                                controlsList: 'nodownload'
                            }}}} style={{margin: "auto"}} url='il_cigno_video.mp4' controls={true} width={"100%"} height={"auto"}/>
                    )
                    : (
                        <ReactPlayer config={{ file: {
                                attributes: {
                                    controlsList: 'nodownload'
                                }}}}   style={{margin: "auto"}} url='il_cigno_video.mp4' controls={true} width={"100%"} height={"auto"}/>
                    )}
            <Space />
            <Space />
            <figure className="text-center">
                <blockquote className="blockquote">
                    <h4 style={{marginBottom: "2em"}}>Affiliazioni </h4>
                    <div className="image-container">
                        <img className="col" src={fin} style={{height: "3em", margin: "0 2em 2em 2em"}}/>
                        <img className="col" src={uisp} style={{height: "4em", margin: "0 2em 2em 2em"}}/>
                        <img className="col" src={libertas} style={{height: "7em", margin: "0 2em 2em 2em"}}/>
                    </div>
                </blockquote>
            </figure>
        </div>

    )
}

export default ChiSiamo