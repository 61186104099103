import React from "react";
import CaroselloStandard from "../commons/CaroselloStandard";
import bastone from "../images/Fitness/IMG_7566.jpg"
import addominali from "../images/Fitness/IMG_7562.jpg"
import grandangolo from "../images/Fitness/IMG_9229.jpg"
import sdraiati from "../images/Fitness/IMG_9237 2.jpg"
import stretching from "../images/Fitness/IMG_9232 2.jpg"
import gruppo from "../images/Fitness/IMG_9265 2.jpg"
import Titolo from "../commons/Titolo";
import FitnessAccordion from "../components/fitness/FitnessAccordion";
import FitnessInfoCorsi from "../components/fitness/FitnessInfoCorsi";
import ScrollToTopOnMount from "../components/ScrollToTopOnMount";
import {BrowserRouter} from "react-router-dom";
import CardMultitessere from "../components/CardMultitessere";
import Space from "../commons/Space";
import TitoloSezioni from "../commons/TitoloSezioni";
import SpaceL from "../commons/SpaceL";


function Fitness() {
    return(
        <div>
            <ScrollToTopOnMount />

            <Titolo backgroundImage={bastone} title={"Fitness"}/>
            <SpaceL />
            <TitoloSezioni title={"ORARI E CORSI"} />
            <SpaceL />
            <FitnessAccordion />
            <FitnessInfoCorsi/>
            <Space />
            <TitoloSezioni title={"PREZZI"} />
            <div className="container text-center" style={{marginTop: "2em"}}>
                <div className="row align-items-start">
                    <div className="col">
                        <div style={{fontFamily: "Hammersmith One", fontSize: "20px"}}>MONOSETTIMANALE</div>
                        <strong>3 mesi</strong> 136 € + iscrizione 10 €<br/>
                        <strong>6 mesi</strong> 208 € + iscrizione 20 €<br/>
                        <strong>9 mesi</strong> 293 € + iscrizione 30 €<br/>
                    </div>
                    <div className="col align-self-center">
                        <div style={{fontFamily: "Hammersmith One", fontSize: "20px"}}>BISETTIMANALE</div>
                        <strong>3 mesi</strong> 188 € + iscrizione 10 €<br/>
                        <strong>6 mesi</strong> 299 € + iscrizione 20 €<br/>
                        <strong>9 mesi</strong> 399 € + iscrizione 30 €<br/>
                    </div>
                </div>
                <Space />
                <h2 className="d-flex flex-row justify-content-center m-auto" style={{fontFamily: "Hammersmith One"}}>Multitessera POKER</h2>
                tutti i corsi fitness di gruppo di intervallo e sera <br/>
                <strong>3 mesi</strong> 248 € + iscrizione 10 €<br/>
                <strong>6 mesi</strong> 408 € + iscrizione 20 €<br/>
                <strong>9 mesi</strong> 560 € + iscrizione 30 €<br/>
                <SpaceL />
                <div className="d-flex flex-column justify-content-center m-auto">
                    <h4 style={{fontFamily: "Hammersmith One"}}>Scopri le nostre multitessere</h4>
                    <CardMultitessere />
                </div>
            </div>

            <Space />
                <CaroselloStandard foto1={bastone} foto2={gruppo} foto3={sdraiati} foto4={grandangolo} foto5={stretching} foto6={addominali} />
            <Space />

        </div>
    )
}


export default Fitness
