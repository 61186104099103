import React from "react";
import Space from "../../commons/Space";
import TitoloSezioni from "../../commons/TitoloSezioni";

function FitnessInfoCorsi() {
    return(
        <div className="info-corsi" id="infoFitness">
            <Space />
            <br/>
            <dl className="row" style={{marginLeft: "2em", maxWidth: "100%"}}>

                <dt className="col-sm-3">Postural Stretching</dt>
                <dd className="col-sm-9 mb-4">
                    Attività motoria adatta a tutte le età, mirata a ristabilire l'equilibrio corporeo e una postura corretta
                    <br/> Attraverso esercizi di respirazione, allungamento, rinforzo e resistenza muscolare
                </dd>

                <dt className="col-sm-3">Pilates</dt>
                <dd className="col-sm-9 mb-4">
                    Ginnastica di tipo rieducativo, preventivo e terapeutico focalizzata sul controllo della postura tramite la regolazione del baricentro
                    <br/> Sviluppa fluidità, precisione di movimentie consapevolezza del proprio corpo
                </dd>

                <dt className="col-sm-3">Total Body e Up Body + Leg Crash</dt>
                <dd className="col-sm-9 mb-4">
                    Allenamento completo che stimola e coinvolge tutto il corpo.
                    <br/> Basato su un mix di esercizi differenti: statici, dinamici, di equilibrio funzionale, forza e definizione muscolare
                </dd>


                <dt className="col-sm-3">Zumba</dt>
                <dd className="col-sm-9 mb-4">
                    Perfetto connubio tra danze caraibiche e ginnastica aerobica mirata a bruciare calorie, tonificare e diminuire lo stress
                    <br/> La giusta combinazione per rimanere in forma divertendosi
                </dd>


                <dt className="col-sm-3">Hataha Yoga</dt>
                <dd className="col-sm-9 mb-4">
                    Letteralmente sole e luna che rappresentano anima e coscienza
                    <br/> Conosciuto anche come Yoga della forza, è bastato sull'esecuzione di posizioni e tecniche di respirazione
                    <br/> Apporta benefici sia a livello fisico che mentale prevenendo dolori muscolari e alleviando stress, ansia e disturbi depressivi
                </dd>

                <dt className="col-sm-3">Functional Training</dt>
                <dd className="col-sm-9 mb-4">
                    Allenamento finalizzato a migliorare il movimento dell'intero corpo e non del singolo distretto muscolare
                    <br/> Stimola mobilità, velocità, forza, resistenza e coordinazione
                </dd>

                <dt className="col-sm-3">Fit Boxe</dt>
                <dd className="col-sm-9 mb-4">
                    Attività aerobica di intensità medio-alta che consiste nel poratare tecniche di braccia e gambe su un sacco o a vuoto
                    <br/> Prende spunto dal pugilato, dalla Kickboxing e dalla Muay Thai senza però entrare in contatto con l'avversario
                    <br/> Favorisce tonificazione, coordinazione, forza, circolazione sanguigna, migliora le capacità respiratorie aumentando la resistenza
                </dd>

                <dt className="col-sm-3">CIRCL Mobility</dt>
                <dd className="col-sm-9 mb-4">
                    Basata sulla scienza del movimento funzionale si concentra su flessibilità, respirazione ed esercizi di mobilità
                </dd>

            </dl>
        </div>
    )
}

export default FitnessInfoCorsi