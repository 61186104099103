import React from "react";

function TabellaDanzaMono60() {
    return(
        <div className="table-container">
            <table className="table table-bordered m-auto" style={{width: "90%"}}>
                <colgroup>
                    <col style={{ width: "16.666%", minWidth: "100px"}} />
                    <col style={{ width: "16.666%", minWidth: "100px" }} />
                    <col style={{ width: "16.666%", minWidth: "100px" }} />
                    <col style={{ width: "16.666%", minWidth: "100px" }} />
                    <col style={{ width: "16.666%", minWidth: "100px" }} />
                    <col style={{ width: "16.666%", minWidth: "100px" }} />
                </colgroup>
                <thead>
                <tr>
                    <th className="header-cell" scope="col">Lun</th>
                    <th className="header-cell" scope="col">Mar</th>
                    <th className="header-cell" scope="col">Mer</th>
                    <th className="header-cell" scope="col">Gio</th>
                    <th className="header-cell" scope="col">Ven</th>
                    <th className="header-cell" scope="col">Sab</th>
                </tr>
                </thead>
                <tbody>
                <tr>
                    <td className="data-cell"></td>
                    <td className="data-cell"></td>
                    <td className="data-cell"></td>
                    <td className="data-cell"></td>
                    <td className="data-cell"></td>
                    <td className="data-cell">
                        9:15 - 10:15<br/><a href="#info" style={{color: "black"}}>Classica Materne </a>(fino a 10 anni)
                    </td>
                </tr>
                <tr>
                    <td className="data-cell"></td>
                    <td className="data-cell"></td>
                    <td className="data-cell"></td>
                    <td className="data-cell"></td>
                    <td className="data-cell"></td>
                    <td className="data-cell">
                        10:15 - 11:30<br/><a href="#info" style={{color: "black"}}>Acrobatica Materne </a>(fino a 10 anni)
                    </td>
                </tr>
                <tr>
                    <td className="data-cell"></td>
                    <td className="data-cell">
                        16:45 - 17:45 <br/> <a href="#info" style={{color: "black"}}>Contemporanea intermedio</a><br/>(10-14 anni)
                    </td>
                    <td className="data-cell">
                        17:00 - 18:00 <br/> <a href="#info" style={{color: "black"}}>Moderna Materne</a><br/>(4-6 anni)
                    </td>
                    <td className="data-cell"></td>
                    <td className="data-cell">
                        16:45 - 17:45 <br/> <a href="#info" style={{color: "black"}}>Contemporanea e Floor Work Open</a><br/>(10-14 anni)
                    </td>
                    <td className="data-cell"></td>
                </tr>
                <tr>
                    <td className="data-cell data-lg">
                        18:00 - 19:00 <br/> <a href="#info" style={{color: "black"}}>Cigno Cheer Open </a>
                    </td>
                    <td className="data-cell"></td>
                    <td className="data-cell">
                        18:00 - 19:00 <br/> <a href="#info" style={{color: "black"}}>Classica Open</a>
                    </td>
                    <td className="data-cell data-lg">
                        18:00 - 19:00 <br/> <a href="#info" style={{color: "black"}}>Cigno Cheer Open</a>
                    </td>
                    <td className="data-cell"></td>
                    <td className="data-cell"></td>
                </tr>
                <tr>
                    <td className="data-cell"></td>
                    <td className="data-cell"></td>
                    <td className="data-cell">19:00 - 20:00 <br/> <a href="#info" style={{color: "black"}}>Classica Open</a></td>
                    <td className="data-cell"></td>
                    <td className="data-cell"></td>
                    <td className="data-cell">
                    </td>
                </tr>
                </tbody>
            </table>
        </div>
    )
}

export default TabellaDanzaMono60