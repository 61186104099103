import React, {cloneElement, useRef, useState} from "react";
import {Link, NavLink, useNavigate} from "react-router-dom";
import logo from "../images/home/logoBar.png"
import 'bootstrap/dist/css/bootstrap.min.css';
import "../styles/Bar.css"
import Orari from "./Orari";
import ScrollToTopOnMount from "./ScrollToTopOnMount";


interface BarProps { }

function Bar(_: BarProps) {
    const orariRef = useRef<HTMLDivElement>(null);
    const [isNavOpen, setIsNavOpen] = useState(false);

    const handleNavClick = () => {
        setIsNavOpen(!isNavOpen); // Inverti lo stato quando viene cliccato il menu
    }

    const closeNav = () => {
        setIsNavOpen(false); // Chiudi il menu quando viene selezionata un'opzione
    }

    const handleOrariClick = () => {
        if (orariRef.current) {
            const orariPosition = orariRef.current.offsetTop;
            window.scrollTo({ top: orariPosition, behavior: 'smooth' });
        }
        closeNav();
    };




    return (
        <div>
        <nav className="navbar navbar-expand-xxl fixed-top" style={{ backgroundColor: "#2F719D", height: "5em", zIndex: 1000}}>
            <div className="container-fluid" style={{ alignContent: "end" }}>
                <Link  className="navbar-brand text-white" to="/">
                    <img className="nav-item-logo" src={logo} style={{ width: "8em", marginLeft: "1em" }} alt={"IL CIGNO"} onClick={closeNav}/>
                </Link>
                <button
                    className="navbar-toggler"
                    type="button"
                    style={{marginRight: "1em"}}
                    data-bs-toggle="collapse"
                    data-bs-target="#navbarNav"
                    aria-controls="navbarNav"
                    aria-expanded={isNavOpen ? "true" : "false"}
                    aria-label="Toggle navigation"
                    onClick={handleNavClick}
                >
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div className={`collapse navbar-collapse custom-navbar-collapse ${isNavOpen ? "navshow" : ""}`} id="navbarNav" style={{width: "100%" }} >
                    <ul className="navbar-nav ms-auto" >
                        <li className="nav-item dropdown" >
                            <a className="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                Il Centro
                            </a>
                            <ul className="dropdown-menu dropdown-menu-dark">
                                <li>
                                    <NavLink className="dropdown-item" to="/chisiamo" onClick={closeNav}>
                                        Chi Siamo
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink className="dropdown-item" to="/contatti" onClick={closeNav}>
                                        Contatti
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink className="dropdown-item" to="/calendario" onClick={closeNav}>
                                        Calendario
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink className="dropdown-item" to="/regolamento" onClick={closeNav}>
                                        Regolamento
                                    </NavLink>
                                </li>
                            </ul>
                        </li>
                        <li className="nav-item">
                            <NavLink className="nav-link active text-white" to="/piscina" onClick={closeNav}>
                                Piscina
                            </NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink className="nav-link active text-white" to="/palestra" onClick={closeNav}>
                                Palestra
                            </NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink className="nav-link active text-white" to="/fitness" onClick={closeNav}>
                                Fitness
                            </NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink className="nav-link active text-white" to="/danza" onClick={closeNav}>
                                Danza
                            </NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink className="nav-link active text-white" to="/runnering" onClick={closeNav}>
                                Runnering
                            </NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink className="nav-link active text-white" to="/spa" onClick={closeNav}>
                                SPA
                            </NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink className="nav-link active text-white" to="/multitessere" onClick={closeNav}>
                                Multitessere
                            </NavLink>
                        </li>
                        <li className="nav-item dropdown">
                            <a className="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                Altri servizi
                            </a>
                            <ul className="dropdown-menu dropdown-menu-dark">
                                <li>
                                    <NavLink className="dropdown-item" to="/osteopata" onClick={closeNav}>
                                        Osteopata
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink className="dropdown-item" to="/visitemediche" onClick={closeNav}>
                                        Visite mediche
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink className="dropdown-item" to="/abbigliamento" onClick={closeNav}>
                                        Abbigliamento
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink className="dropdown-item" to="/ilcignocafé" onClick={closeNav}>
                                        Il Cigno Café
                                    </NavLink>
                                </li>
                            </ul>
                        </li>
                        <li className="nav-item">
                            <NavLink className="nav-link active text-white" to="/news" onClick={closeNav}>
                                News
                            </NavLink>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link active text-white" href="#orari" data-bs-toggle="collapse" data-bs-target="#collapseOrari" aria-expanded="false" aria-controls="collapseOrari">
                                <i className="bi bi-clock text-white d-none d-xxl-block" style={{cursor:"pointer"}} onClick={handleOrariClick}></i>
                                <div className={"d-xxl-none"} onClick={handleOrariClick}>Orari</div>
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </nav>
            <div className="collapse" id="collapseOrari">
                <div className="card card-body d-flex flex-row" ref={orariRef} style={{border:"none", position: "relative", justifyContent: "space-between" }}>
                    <span>
                        <Orari />
                    </span>
                    <div className="nav-link active d-xxl-none text-black" data-bs-toggle="collapse" data-bs-target="#collapseOrari" aria-expanded="false" aria-controls="collapseOrari"
                        style={{marginTop: "6em"}}
                    >
                        <i className="bi-x-lg" style={{cursor:"pointer", color: "black", fontSize: "22px"}}></i>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Bar;
