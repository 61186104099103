import React from "react";
import CaroselloStandard from "../commons/CaroselloStandard";
import foto1 from "../images/Spa/IMG_1947.jpg"
import Titolo from "../commons/Titolo";
import TabellaSpa from "../components/spa/TabellaSpa";
import ScrollToTopOnMount from "../components/ScrollToTopOnMount";
import Space from "../commons/Space";
import CaroselloSpa from "../components/spa/CaroselloSpa";
import CardMultitessere from "../components/CardMultitessere";
import TitoloSezioni from "../commons/TitoloSezioni";


function Spa() {
    return(
        <div>
            <ScrollToTopOnMount />
            <Titolo backgroundImage={foto1} title={"SPA"}/>
            <Space />
            <TitoloSezioni title={"REPARTO TERMALE"} />
            <Space />
            <div className="d-flex flex-row flex-wrap align-items-center justify-content-center">
                <TabellaSpa />
                <p className="text-center" style={{marginTop: "2em", padding: "1em 2em 1em 2em"}}>
                    <strong>Trimestre</strong> 204 € + iscrizione: 10 €<br/>
                    <strong>Semestre</strong> 374 € + iscrizione: 20 €<br/>
                    <strong>Anno</strong> 555 € + iscrizione: 30 €
                </p>
            </div>
            <Space />
            <CaroselloSpa />

            <Space />
            <div className="d-flex flex-column text-center align-items-center justify-content-center">
                <TitoloSezioni title={"Multitessere"} />
                <h4 style={{fontFamily: "Hammersmith One"}}>Scopri le nostre multitessere</h4>
                <CardMultitessere />
            </div>
            <Space />

        </div>
    )
}


export default Spa
