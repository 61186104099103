import React from "react";
import CaroselloHome from "../components/home/CaroselloHome";
import "../styles/Home.css"
import {useNavigate} from "react-router-dom";
import GroupCard from "../components/home/GroupCard";
import ScrollToTopOnMount from "../components/ScrollToTopOnMount";
import Space from "../commons/Space";
import TitoloSezioni from "../commons/TitoloSezioni";
import SpaceL from "../commons/SpaceL";

interface HomeProps {

}


function Home(_:HomeProps) {
    const navigate = useNavigate()
    return(
        <div>
            <ScrollToTopOnMount />
            <CaroselloHome />
            <Space />
            <div className="d-flex flex-column align-items-center">
            <TitoloSezioni title="Attività" />
                <br/>
            <GroupCard />
                <Space />
                <figure className="text-center">
                    <blockquote className="blockquote">
                        <button
                            style={{maxWidth: "40em", minWidth: "20em", height: "4em", borderRadius: "1em"}}
                            type="button" className="btn custom-btn"
                            onClick={()=> navigate("/chisiamo")}
                        >
                            <h4>Scopri il centro</h4>
                        </button>
                    </blockquote>
                </figure>
            </div>
            <Space />
        </div>
    )
}

export default Home