import React, { useState, useEffect } from "react";
import ProvaAccordionSection from "../components/multitessere/ProvaAccordionSection";
import DueMultitessereSezioni from "../components/multitessere/DueMultitessereSezioni";
import foto1 from "../images/multitessere.png";
import Titolo from "../commons/Titolo";
import ScrollToTopOnMount from "../components/ScrollToTopOnMount";
import Space from "../commons/Space";
import SpaceL from "../commons/SpaceL";
import AllertIscrizione from "../components/multitessere/AllertIscrizione";

function Multitessere() {
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };

        window.addEventListener("resize", handleResize);

        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    return (
        <div>
            <ScrollToTopOnMount />
            <Titolo backgroundImage={foto1} title={"MULTITESSERE"}/>
            <SpaceL />
            <figure className="text-center">
                <blockquote className="blockquote">
                    <p style={{fontSize: "30px", padding:"1em", fontFamily: "Hammersmith One"}}>Scegli la formula di abbonamento più adatta a te </p>
                </blockquote>
            </figure>

            {windowWidth > 900 ? (
                <DueMultitessereSezioni />
            ) : (
                <ProvaAccordionSection />
            )}
            <SpaceL />
            <AllertIscrizione />
            <Space />


        </div>
    );
}

export default Multitessere;
