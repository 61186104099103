import React from "react";
import CaroselloStandard from "../../commons/CaroselloStandard";
import foto1 from "../../images/Nuoto/Acquafitness/IMG_8955-2.jpg"
import foto2 from "../../images/Nuoto/Acquafitness/IMG_8964-2.jpg"
import foto3 from "../../images/Nuoto/Acquafitness/IMG_8939.jpg"
import foto4 from "../../images/Nuoto/Acquafitness/IMG_8952.jpg"
import foto5 from "../../images/Nuoto/Acquafitness/IMG_8920.jpg"
import foto6 from "../../images/Nuoto/Acquafitness/IMG_8972.jpg"
import foto7 from "../../images/Nuoto/Acquafitness/IMG_8886-3.jpg"
import foto8 from "../../images/Nuoto/Acquafitness/IMG_1784.jpg"
import copertina from "../../images/Nuoto/Acquafitness/IMG_8925-3.jpg"

import Titolo from "../../commons/Titolo";
import {CANCELLED} from "dns";
import AcquaFitnessAccordion from "../../components/acqua_fitness/AcquaFitnessAccordion";
import ScrollToTopOnMount from "../../components/ScrollToTopOnMount";
import {useNavigate} from "react-router-dom";
import AcquaFitnessInfoCorsi from "../../components/acqua_fitness/AcquaFitnessInfoCorsi";
import Space from "../../commons/Space";
import FrecciaIndietro from "../../commons/FrecciaIndietro";
import TitoloSezioni from "../../commons/TitoloSezioni";
import SpaceL from "../../commons/SpaceL";


function AcquaFitness() {
    return(
        <div>
            <ScrollToTopOnMount />
            <Titolo backgroundImage={copertina} title={"Acquafitness"}/>
            <FrecciaIndietro indirizzo={"/piscina"} />

            <figure className="text-center">
                <blockquote className="blockquote">
                    <p>
                        <h3>Attività aerobica in acqua di intensità medio/alta a ritmo di musica</h3>
                        <h4>con ausilio di supporti didattici</h4>
                        <br/>
                        <br/>
                        Mirata a migliorare <strong>tonificazione muscolare</strong>, <strong>flessibilità</strong>, <strong>agilità</strong> e <strong>forza fisica</strong>
                        <br/>
                        <br/>
                        <strong>Favorisce</strong> il sistema cardiocircolatorio
                        <br/>
                        <strong>Allieva</strong> dolori, rigidità e stress
                        <br/>
                        <strong>Previene</strong> i traumi muscolari
                    </p>
                </blockquote>
            </figure>
            <Space />
                <AcquaFitnessAccordion />
            <div id="infoAcquaFitness"></div>

            <Space />
            <TitoloSezioni title={"Corsi"} />
            <br/>
                <AcquaFitnessInfoCorsi/>
            <div className="alert alert-primary d-flex align-items-center align-self-center m-auto" role="alert">
                <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" className="bi bi-info-circle-fill flex-shrink-0" viewBox="0 0 16 16">
                    <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16m.93-9.412-1 4.705c-.07.34.029.533.304.533.194 0 .487-.07.686-.246l-.088.416c-.287.346-.92.598-1.465.598-.703 0-1.002-.422-.808-1.319l.738-3.468c.064-.293.006-.399-.287-.47l-.451-.081.082-.381 2.29-.287zM8 5.5a1 1 0 1 1 0-2 1 1 0 0 1 0 2"/>
                </svg>
                <div className="text-center" style={{ marginLeft: "2em" }}>
                    Per i corsi in acqua alta si richiede una buona capacità di galleggiamento
                </div>
            </div>

            <Space />
                <CaroselloStandard foto1={foto1} foto2={foto2} foto3={foto3} foto4={foto4} foto5={foto5} foto6={foto6} foto7={foto7} foto8={foto8}/>
            <Space />
        </div>
    )
}


export default AcquaFitness
