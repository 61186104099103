import React from "react";
import TitoloColore from "../commons/TitoloColore";
import ScrollToTopOnMount from "../components/ScrollToTopOnMount";
import Space from "../commons/Space";
import CaroselloStandard from "../commons/CaroselloStandard";
import foto1 from "../images/Osteopata/IMG_9299.jpg"
import foto2 from "../images/Osteopata/IMG_9309.jpg"
import foto3 from "../images/Osteopata/IMG_9301.jpg"
import SpaceL from "../commons/SpaceL";
import osteopata from "../images/Osteopata/IMG_8976-2.jpg"





function Osteopata() {
    return(
        <div>
            <ScrollToTopOnMount />
            <TitoloColore title={"Osteopata"} backgroundColor={"#003F83"} />
            <figure className="text-center" style={{padding: "5em 5em 2em 5em"}}>
                <blockquote className="blockquote">
                    <p>
                        <h2>Studio di osteopatia</h2>
                        <h4>Andrea Pannunzio</h4>
                        Dottore in scienze motorie
                        <br/> OSTEOPATA DO BSc
                        <br/> Per appuntamento 3294383251
                    </p>
                </blockquote>
            </figure>
            <div className="text-center">
                <img className="img-fluid img-thumbnail" style={{maxHeight: "20em"}} src={osteopata}/>
            </div>
            <figure className="text-center" style={{padding: "5em 5em 2em 5em"}}>
                <blockquote className="blockquote">
                    <h2>Corso di ginnastica antalgica</h2>
                    Martedì e giovedì, dalle 11.30 alle 12.30
                </blockquote>
            </figure>

            <CaroselloStandard foto1={foto1} foto2={foto2} foto3={foto3} />
            <Space />

        </div>
    )
}

export default Osteopata