import React from "react";

function TabellaFitnessSera() {
    return(
        <div className="table-container">
            <table className="table table-bordered m-auto" style={{width: "90%"}}>
                <thead>
                <tr>
                    <th className="header-cell" scope="col">Lun</th>
                    <th className="header-cell" scope="col">Mar</th>
                    <th className="header-cell" scope="col">Mer</th>
                    <th className="header-cell" scope="col">Gio</th>
                    <th className="header-cell" scope="col">Ven</th>
                </tr>
                </thead>
                <tbody>
                <tr>
                    <td className="data-cell">
                        19:00 - 19:45 <br/> <a href="#infoFitness" style={{color: "black"}}>Fit Boxe </a>
                    </td>
                    <td className="data-cell">
                        19:00 - 19:45 <br/> <a href="#infoFitness" style={{color: "black"}}>Zumba </a>
                    </td>
                    <td className="data-cell">
                        19:00 - 20:00 <br/> <a href="#infoFitness" style={{color: "black"}}>Pilates</a>
                    </td>
                    <td className="data-cell">
                        19:00 - 19:45 <br/> <a href="#infoFitness" style={{color: "black"}}>Total Body</a>
                    </td>
                    <td className="data-cell">
                        19:00 - 20:00 <br/> <a href="#infoFitness" style={{color: "black"}}>Functional Training</a>
                    </td>
                </tr>
                <tr>
                    <td className="data-cell">
                        19:45 - 20:30 <br/> <a href="#infoFitness" style={{color: "black"}}>Functional Training</a>
                    </td>
                    <td className="data-cell">
                        19:45 - 20:30 <br/> <a href="#infoFitness" style={{color: "black"}}>CIRCL Mobility</a>
                    </td>
                    <td className="data-cell">
                        20:00 - 21:00 <br/> <a href="#infoFitness" style={{color: "black"}}>Hatha Yoga</a>
                    </td>
                    <td className="data-cell">
                        19:45 - 20:30 <br/> <a href="#infoFitness" style={{color: "black"}}>Zumba</a>
                    </td>
                    <td className="data-cell">
                        20:00 - 21:00 <br/> <a href="#infoFitness" style={{color: "black"}}>Pilates</a>
                    </td>
                </tr>
                </tbody>
            </table>
        </div>
    )
}

export default TabellaFitnessSera