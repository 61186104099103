import React from "react";
import CaroselloStandard from "../../commons/CaroselloStandard";
import foto from "../../images/Nuoto/Individuali/IMG_8409.jpg"
import "../../styles/List.css"

import foto1 from "../../images/Nuoto/Individuali/IMG_8360.jpg"
import foto4 from "../../images/Nuoto/Individuali/IMG_8387.jpg"
import foto2 from "../../images/Nuoto/Individuali/IMG_8397.jpg"
import foto3 from "../../images/Nuoto/Individuali/IMG_8435.jpg"
import foto5 from "../../images/Nuoto/Individuali/IMG_8448.jpg"
import foto6 from "../../images/Nuoto/Individuali/IMG_8630.jpg"
import foto7 from "../../images/Nuoto/Individuali/IMG_importata.jpg"
import foto8 from "../../images/Nuoto/Individuali/IMG_importata2.jpg"

import Titolo from "../../commons/Titolo";
import ScrollToTopOnMount from "../../components/ScrollToTopOnMount";
import {useNavigate} from "react-router-dom";
import Space from "../../commons/Space";
import FrecciaIndietro from "../../commons/FrecciaIndietro";
import Carosello5Foto from "../../commons/CaroselloStandard";


function LezioniIndividuali() {
    const navigate = useNavigate()
    return(
        <div>
            <ScrollToTopOnMount />
            <Titolo backgroundImage={foto} title={"Lezioni individuali"}/>
            <FrecciaIndietro indirizzo={"/piscina"} />
            <figure className="text-center">
                <blockquote className="blockquote">
                    <h1 style={{fontFamily: "Hammersmith One", padding: "1em"}}>Pacchetti standard</h1>
                        Lezioni da 45 minuti con un istruttore dedicato a te e alle tue esigenze
                        <br/>
                    Adatto a chi vuole imparare a nuotare o vuole perfezionare il proprio stile
                    <p>
                        <br/>
                    <strong>Pacchetto 10 lezioni</strong><br/>
                        280 € + iscrizione 10 € <br/>
                        2° allievo paga metà
                    </p>
                     <br/>
                    *giorni e orari da concordare con l'istruttore
                </blockquote>
            </figure>
            <br/>
            <figure className="text-center">
                <blockquote className="blockquote d-flex flex-column align-items-center justify-content-center vh-100">
                    <h1 style={{ fontFamily: "Hammersmith One", padding: "1em" }}>Pacchetto speciale</h1>
                    <p>
                        <div className="ms-auto" style={{marginBottom: "3em"}}>
                            Il corso è dedicato a persone con fragilità che si avvicinano al mondo dell'acqua e desiderano imparare a nuotare.
                            <br/>
                            Gli istruttori specializzati valutano esigenze e abilità di ogni singolo allievo e progettano il percorso migliore da intraprendere.
                            <br/>
                        </div>
                        <div className="d-flex flex-wrap justify-content-center">
                            Le finalità del corso sono:
                            <ul className="custom-list">
                                <li>raggiungimento del benessere in acqua (acquaticità)</li>
                                <li>acquisizione delle tecniche natatorie</li>
                                <li>stimolazione della relazione con gli altri</li>
                                <li>sviluppo dell'autonomia personale</li>
                            </ul>
                        </div>
                    </p>

                    <br />
                    <p><strong>Pacchetto 10 lezioni</strong> da 45 minuti <br/>
                        250 € + iscrizione 10 € <br/>
                        2° allievo paga metà
                    </p>
                    <br/>
                    *giorni e orari da concordare con l'istruttore
                    <br/>
                    *possibilità di abbonamento stagionale
                </blockquote>
            </figure>

            <Space />
                <CaroselloStandard foto1={foto1} foto2={foto2} foto3={foto3} foto4={foto4} foto5={foto5} foto6={foto6} foto7={foto7} foto8={foto8}/>
            <Space />
        </div>
    )
}


export default LezioniIndividuali
