import React from "react";
import "./TitoloSezioni.css"


interface TitoloSezioniProps {
    title: string;
}

function TitoloSezioni ({title}:TitoloSezioniProps) {
    return(
        <div className="sottotitolo-title-container row m-auto text-center">
            <h1 className="sottotitolo-title">{title}</h1>
        </div>
    )
}

export default TitoloSezioni