import React, {useEffect, useState} from "react";
import CardNuoto from "../../components/nuoto/CardNuoto";
import nuotoLibero from "../../images/Nuoto/Nuoto_libero/IMG_1555_tagliata-2.jpg"
import corsi from "../../images/Nuoto/corsiNuoto/IMG_8526-3.jpg"
import individuali from "../../images/Nuoto/Individuali/IMG_8409.jpg"
import acquaGym from "../../images/Nuoto/Acquafitness/IMG_8886-3.jpg"
import riabilitazione from "../../images/Nuoto/Riabilitazione/foto5.jpg"
import master from "../../images/Nuoto/Master/DSCF9116.jpg"
import "../../styles/CardNuoto.css"
import CaroselloStandard from "../../commons/CaroselloStandard";
import foto1 from "../../images/Nuoto/IMG_8637.jpg"
import {url} from "inspector";
import Titolo from "../../commons/Titolo";
import ScrollToTopOnMount from "../../components/ScrollToTopOnMount";
import Space from "../../commons/Space";
import CardMultitessere from "../../components/CardMultitessere";
import TitoloSezioni from "../../commons/TitoloSezioni";
import { ReactPhotoSphereViewer } from 'react-photo-sphere-viewer';
import foto360 from "../../images/home/foto360_noCav00086401.jpg"
import "../../styles/360viewer.css"
import DanzaPacchettixxl from "../../components/danza/DanzaPachettixxl";
import DanzaPacchettiMedia from "../../components/danza/DanzaPacchettiMedia";


function Nuoto() {
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };

        window.addEventListener("resize", handleResize);

        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);
    return(
        <div>
            <ScrollToTopOnMount />
            <Titolo title={"Piscina"} backgroundImage={foto1} />
            <Space />
            <TitoloSezioni title={"I nostri corsi"} />
            <div className="card-container d-flex flex-row  justify-content-center flex-wrap">
                <div className="d-flex flex-row flex-wrap justify-content-center">
                    <CardNuoto image={corsi} title={"Corsi di nuoto"} indirizzo={"/corsinuoto"}/>
                    <CardNuoto image={nuotoLibero} title={"Nuoto libero"} indirizzo={"/nuotolibero"}/>
                    <CardNuoto image={individuali} title={"Lezioni individuali"} indirizzo={"/lezionindividuali"} />
                </div>
                <div className="d-flex flex-row flex-wrap justify-content-center">
                    <CardNuoto image={acquaGym} title={"Acquafitness"} indirizzo={"/acquafitness"}/>
                    <CardNuoto image={riabilitazione} title={"Riabilitazione motoria"} indirizzo={"/riabilitazionemotoria"}/>
                    <CardNuoto image={master} title={"Master"} indirizzo={"/master"} />
                </div>
            </div>

            <Space/>

            {windowWidth > 900 ? (
                <div>
                    <figure className="text-center">
                        <blockquote className="blockquote">
                            <p style={{fontSize: "30px", padding:"1em", fontFamily: "Hammersmith One"}}> Scorri sull'immagine per visualizzare la piscina a 360°</p>
                        </blockquote>
                    </figure>
                <div className="m-auto" style={{width: "80%"}}>
                    <ReactPhotoSphereViewer src={foto360} height={'80vh'} width={"100%"} defaultYaw={"160"}></ReactPhotoSphereViewer>
                </div>
                </div>
            ):
                windowWidth > 650 && windowWidth < 900 ? (
                    <div>
                        <figure className="text-center">
                            <blockquote className="blockquote">
                                <p style={{fontSize: "25px", padding:"1em", fontFamily: "Hammersmith One"}}> Scorri sull'immagine per visualizzare la piscina a 360°</p>
                            </blockquote>
                        </figure>
                <div className="m-auto" style={{width: "90%"}}>
                    <ReactPhotoSphereViewer  src={foto360} height={'60vh'} width={"100%"} defaultYaw={"160"}></ReactPhotoSphereViewer>
                </div>
                    </div>
            )
            : (
                <div>
                    <figure className="text-center">
                        <blockquote className="blockquote">
                            <p style={{fontSize: "22px", padding:"1em", fontFamily: "Hammersmith One"}}> Scorri sull'immagine per visualizzare la piscina a 360°</p>
                        </blockquote>
                    </figure>
                <div className="m-auto" style={{width: "90%"}}>
                            <ReactPhotoSphereViewer src={foto360} height={'30vh'} width={"100%"} defaultYaw={"160"}></ReactPhotoSphereViewer>
                        </div>
                </div>
                    )}

            <Space />
            <div className="d-flex flex-column text-center align-items-center justify-content-center">
                <TitoloSezioni title={"Multitessere"} />
                <h4 style={{fontFamily: "Hammersmith One"}}>Scopri le nostre multitessere</h4>
                <CardMultitessere />
            </div>
            <Space />

        </div>
    )
}

export default Nuoto