import React from "react";
import Space from "../../commons/Space";
import TitoloSezioni from "../../commons/TitoloSezioni";
import "./DanzaInfoCorsi.css"

function DanzaInfoCorsi() {
    return(
        <div className="info-corsi" style={{width: "50%", margin: "auto"}}>
            <dl className="row align-content-center" >
                <dt className="col-sm-5">Moderna Materne</dt>
                <dd className="col-sm-5 mb-4">
                    4-8 anni
                </dd>


                <dt className="col-sm-5">Classica Materne </dt>
                <dd className="col-sm-5 mb-4">
                    4-10 anni
                </dd>

                <dt className="col-sm-5">Acrobatica Materne </dt>
                <dd className="col-sm-5 mb-4">
                    4-10 anni
                </dd>

                <dt className="col-sm-5">Contemporanea e Floor Work Open</dt>
                <dd className="col-sm-5 mb-4">
                    10-14 anni
                </dd>

                <dt className="col-sm-5">Contemporanea intermedio</dt>
                <dd className="col-sm-5 mb-4">
                    10-14 anni
                </dd>

                <dt className="col-sm-5">Acrobatica intermedio  </dt>
                <dd className="col-sm-5 mb-4">
                    10-14 anni
                </dd>

                <dt className="col-sm-5">Classica intermedio</dt>
                <dd className="col-sm-5 mb-4">
                    9-13 anni
                </dd>

                <dt className="col-sm-5">Moderna International</dt>
                <dd className="col-sm-5 mb-4">
                    9-13 anni
                </dd>

                <dt className="col-sm-5">Moderna Experience avanzato</dt>
                <dd className="col-sm-5 mb-4">
                    10-14 anni
                </dd>

                <dt className="col-sm-5">Classica Open </dt>
                <dd className="col-sm-5 mb-4">
                    Senza limiti di età
                </dd>

                <dt className="col-sm-5">Cigno Cheer Open </dt>
                <dd className="col-sm-5 mb-4">
                    Senza limiti di età
                </dd>

            </dl>
        </div>
    )
}

export default DanzaInfoCorsi