import React from "react";

function TabellaAcquaFitBi() {
    return(
        <div className="table-container">
            <table className="table table-bordered m-auto" style={{width: "90%"}}>
                <thead>
                <tr>
                    <th className="header-cell" scope="col">Lun</th>
                    <th className="header-cell" scope="col">Mar</th>
                    <th className="header-cell" scope="col">Mer</th>
                    <th className="header-cell" scope="col">Gio</th>
                    <th className="header-cell" scope="col">Ven</th>
                </tr>
                </thead>
                <tbody>
                <tr>
                    <td className="data-cell vasca-grande">
                        9:15 - 10:00 <br/> <a href="#infoAcquaFitness" style={{color: "black"}}>Acqua Fitness</a>
                    </td>
                    <td className="data-cell "></td>
                    <td className="data-cell"></td>
                    <td className="data-cell vasca-grande">
                        9:15 - 10:00 <br/> <a href="#infoAcquaFitness" style={{color: "black"}}>Acqua Fitness</a>
                    </td>
                    <td className="data-cell "></td>
                </tr>
                <tr>
                    <td className="data-cell vasca-grande">
                        10:00 - 10:45 <br/> <a href="#infoAcquaFitness" style={{color: "black"}}>Acqua Fitness</a>
                    </td>
                    <td className="data-cell"></td>
                    <td className="data-cell"></td>
                    <td className="data-cell vasca-grande">
                        10:00 - 10:45 <br/> <a href="#infoAcquaFitness" style={{color: "black"}}>Acqua Fitness</a>
                    </td>
                    <td className="data-cell"></td>
                </tr>
                <tr>
                    <td className="data-cell vasca-grande">
                        12:40 - 13:25 <br/> <a href="#infoAcquaFitness" style={{color: "black"}}>Acqua Fitness</a>
                    </td>
                    <td className="data-cell"></td>
                    <td className="data-cell"></td>
                    <td className="data-cell vasca-grande">
                        12:40 - 13:25 <br/> <a href="#infoAcquaFitness" style={{color: "black"}}>Acqua Fitness</a>
                    </td>
                    <td className="data-cell"></td>
                </tr>
                <tr>
                    <td className="data-cell vasca-grande">
                        13:30 - 14:15 <br/> <a href="#infoAcquaFitness" style={{color: "black"}}>Acqua Fitness</a>
                    </td>
                    <td className="data-cell"></td>
                    <td className="data-cell"></td>
                    <td className="data-cell vasca-grande">
                        13:30 - 14:15 <br/> <a href="#infoAcquaFitness" style={{color: "black"}}>Acqua Fitness</a>
                    </td>
                    <td className="data-cell"></td>
                </tr>
                </tbody>
            </table>
        </div>
    )
}

export default TabellaAcquaFitBi