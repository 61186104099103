import React from "react";
import TitoloColore from "../commons/TitoloColore";
import ScrollToTopOnMount from "../components/ScrollToTopOnMount";
import Space from "../commons/Space";
import CardNews from "../components/CardNews";
import prova from "../images/Nuoto/nuoto_libero.jpg"


function News() {
    return(
        <div>
            <ScrollToTopOnMount />
        <TitoloColore title={"News"} backgroundColor={"#003F83"} />

        </div>
    )
}

export default News