import React from "react";
import "../../styles/Tabella.css"


interface TabellaOrariProps {

}

function TabellaOrariAcq() {
    return(
        <div>
            <table className="table table-bordered m-auto" style={{width: "90%", maxWidth: "30em"}}>
                <thead>
                <tr>
                    <th className="header-cell" scope="col">Sabato</th>
                    <th className="header-cell" scope="col">Domenica</th>
                </tr>
                </thead>
                <tbody>
                <tr>
                    <td className="data-cell">9:00 - 9:40</td>
                    <td className="data-cell">
                        9:10 - 9:40
                        <br/>
                        (neonatale 6-12 mesi)
                    </td>
                </tr>
                <tr>
                    <td className="data-cell">9:45 - 10:25</td>
                    <td className="data-cell">9:45 - 10:15 <br/>(neonatale 6-12 mesi)</td>
                </tr>
                <tr>
                    <td className="data-cell">10:30 - 11:10</td>
                    <td className="data-cell">10:20 - 11:00</td>
                </tr>
                <tr>
                    <td> </td>
                    <td className="data-cell">11:05 - 11:45</td>
                </tr>

                </tbody>
            </table>
        </div>
    )
}
export default TabellaOrariAcq