import React from "react";
import Space from "../../commons/Space";
import TitoloSezioni from "../../commons/TitoloSezioni";

function AcquaFitnessInfoCorsi() {
    return(
        <div className="info-corsi">
            <dl className="row" style={{marginLeft: "2em", maxWidth: "100%"}}>

                <dt className="col-sm-3">Acqua Fitness</dt>
                <dd className="col-sm-9 mb-4">
                    45' di attività alternata in acqua bassa e acqua alta
                </dd>

                <dt className="col-sm-3">Soft Water Gag</dt>
                <dd className="col-sm-9 mb-4">
                    45' di ginnastica dolce in acqua bassa, adatto per chi non sa nuotare
                </dd>

                <dt className="col-sm-3">Acqua Thriathlon</dt>
                <dd className="col-sm-9 mb-4">
                    60' di attività intensa alternata in acqua bassa e acqua alta
                    <br/>
                    Il corso prevede step coreografato in acqua bassa e utilizzo di float bike in acqua alta
                </dd>

                <dt className="col-sm-3">Deep Water</dt>
                <dd className="col-sm-9 mb-4">
                    45' di attività in acqua alta
                </dd>

            </dl>
        </div>
    )
}

export default AcquaFitnessInfoCorsi