import React from "react";
import cuffia from "../../images/Nuoto/Icone/cuffia.png"
import asciugamano from "../../images/Nuoto/Icone/towel.png"
import costume from "../../images/Nuoto/Icone/swimming-suit.png"
import tessera from "../../images/Nuoto/Icone/id-card.png"
import ciabatte from "../../images/Nuoto/Icone/sandals.png"

function CosaPortareNuoto() {
    return(
        <div className="container text-center">
            <h1 style={{fontFamily: "Hammersmith One", padding: "1em"}}>Cosa portare?</h1>
            <div className="row">
                <div className="col">
                    <div style={{height: "6em"}}>
                        <img style={{height: "5em"}} src={tessera}></img></div>
                    <p>Tessera del Cigno</p>
                </div>
                <div className="col">
                    <div style={{height: "6em"}}>
                        <img style={{height: "5em", marginLeft: "2em"}} src={costume}></img></div>
                    <p>Costume</p>
                </div>
                <div className="col">
                    <div style={{height: "6em"}}>
                        <img style={{height: "5em"}} src={cuffia}></img>
                    </div>
                    <p>Cuffia</p>
                </div>
                <div className="col">
                    <div style={{height: "6em"}}>
                        <img style={{height: "5em"}} src={asciugamano}></img></div>
                    <p>Asciugamano</p>
                </div>
                <div className="col">
                    <div style={{height: "6em"}}>
                        <img style={{height: "5em"}} src={ciabatte}></img></div>
                    <p>Ciabatte</p>
                </div>
            </div>
        </div>
    )
}
export default CosaPortareNuoto