import React, {useEffect, useState} from "react";
import TitoloColore from "../commons/TitoloColore";
import ScrollToTopOnMount from "../components/ScrollToTopOnMount";
import Space from "../commons/Space";
import aon from "../images/logo/Aon.svg.png"
import cartasi from "../images/logo/cartasi-logo.jpg"
import doubleyou from "../images/logo/doubleyou-welfare-aziendale-5.jpg"
import edenred from "../images/logo/Edenred_Logo.svg.png"
import eudaimon from "../images/logo/eudaimon.png"
import postepay from "../images/logo/Logo_Postepay.svg.png"
import welfareV from "../images/logo/LOGO_WELFARE_VOUCHER_vettoriale-1-e1542627638670.jpg"
import pellegrini from "../images/logo/logo-pellegrini-welfare.png"
import trecuori from "../images/logo/trecuori.png"
import maestro from "../images/logo/Maestro_logo.png"
import pagoBa from "../images/logo/Marchio-PagoBANCOMAT.png"
import mastercard from "../images/logo/MasterCard_Logo.svg.png"
import visa from "../images/logo/Visa_Inc._logo.svg.webp"
import vpay from "../images/logo/VPay_logo_2015.svg.png"






function Regolamento() {
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };

        window.addEventListener("resize", handleResize);

        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);
    return(
        <div>
            <ScrollToTopOnMount />
            <TitoloColore title={"Regolamento"} backgroundColor={"#003F83"} />
            <figure className="" style={{padding: "5em"}}>
                <blockquote className="blockquote">
                    <p>
                        <strong>Quota di iscrizione</strong>
                        <br/>
                        Tutti i frequentatori dovranno corrispondere la quota di iscrizione comprendente la copertura assicurativa infortuni:
                        <br/> 3 mesi/10 ingressi: 10 €
                        <br/> 6 mesi/20 ingressi: 20 €
                        <br/> 9 mesi/annuali: 30 €
                        <br/>
                        <br/>
                        <strong>Certificazione medica</strong>
                        <br/> Senza tale certificazione valida non si potrà essere ammessi alle attività.
                        <br/> Per appuntamenti 022426191
                        <br/>
                        <br/>
                        <strong>Corsi</strong>
                        <br/> Tutti i nostri corsi di gruppo sono a numero chiuso, si accettano quindi iscrizioni fino al limite di capienza e funzionalità.
                        <br/> Per ulteriori richieste sono previste le liste di attesa
                        <br/>
                        <br/> *non sono possibili recuperi o rimborsi dovuti ad assenze personali
                        <br/>
                        <br/>
                        <strong>Rinnovi corsi</strong>
                        <br/> Per mantenere il posto all'interno del corso è necessario rinnovare l'iscrizione entro 7 giorni dopo la scadenza,
                        <br/>in caso contrario si viene inseriti in coda alla lista d'attesa
                        <br/>
                        <br/>
                        <strong>Pagamenti</strong>
                        <br/> Possibilità di rateizzazione senza interessi. La prima rata deve essere saldata all'atto dell'iscrizione.
                        <br/> Per i successivi pagamenti (30-60g) si richiede la puntualità nelle scadenze onde evitare il rifiuto da parte del centro di rateizzare le successive iscrizioni
                        <div className="image-container">
                            <img className="col" src={visa} style={{height: "2em", margin: "2em"}}/>
                            <img className="col" src={mastercard} style={{height: "3em", margin: "2em"}}/>
                            <img className="col" src={pagoBa} style={{height: "3em", margin: "2em"}}/>
                            <img className="col" src={maestro} style={{height: "3em", margin: "2em"}}/>
                            <img className="col" src={postepay} style={{height: "1.5em", margin: "2em"}}/>
                            <img className="col" src={vpay} style={{height: "3em", margin: "2em"}}/>
                            <img className="col" src={cartasi} style={{height: "3em", margin: "2em"}}/>
                        </div>

                        <br/>
                        <br/>
                        <strong>Convenzioni e voucher aziendali</strong>
                        <div className="image-container">
                            <img className="col" src={trecuori} style={{height: "2.5em", margin: "2em"}}/>
                            <img className="col" src={aon} style={{height: "2em", margin: "2em"}}/>
                            <img className="col" src={doubleyou} style={{height: "3em", margin: "2em"}}/>
                            <img className="col" src={edenred} style={{height: "3em", margin: "2em"}}/>
                            <img className="col" src={eudaimon} style={{height: "2em", margin: "2em"}}/>
                            <img className="col" src={welfareV} style={{height: "3.5em", margin: "2em"}}/>
                            <img className="col" src={pellegrini} style={{height: "1.8em", margin: "2em"}}/>
                        </div>


                    </p>
                </blockquote>
            </figure>
        </div>
    )
}

export default Regolamento