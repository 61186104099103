import React from "react";
import CaroselloStandard from "../commons/CaroselloStandard";
import foto1 from "../images/Runnering/IMG_1744.jpg"
import foto2 from "../images/Runnering/IMG_1742.jpg"
import foto3 from "../images/Runnering/IMG_1734.jpg"
import foto4 from "../images/Runnering/IMG_1738.jpg"
import foto5 from "../images/Runnering/StraWoman_1.jpg"
import foto0 from "../images/Runnering/IMG_1719_tagliata.jpg"
import foto6 from "../images/Runnering/Foto_1.jpg"
import foto7 from "../images/Runnering/Foto_2.jpg"
import foto8 from "../images/Runnering/Foto_3.jpg"
import Titolo from "../commons/Titolo";
import TabellaRunnering from "../components/runnering/TabellaRunnering";
import ScrollToTopOnMount from "../components/ScrollToTopOnMount";
import Space from "../commons/Space";
import CardMultitessere from "../components/CardMultitessere";
import TitoloSezioni from "../commons/TitoloSezioni";
import Spa from "./Spa";


function Runnering() {
    return(
        <div>
            <ScrollToTopOnMount />
            <Titolo backgroundImage={foto0} title={"Runnering"}/>
            <Space />

            <figure className="text-center">
                <blockquote className="blockquote">
                    <p>
                        <h2>Allenamento e divertimento di gruppo su tapis roulant</h2>
                        <br/>
                        <h4>Camminare e correre in compagnia sotto la guida del vostro allenatore</h4>
                        <h4>Percorsi personalizzati a ritmo di musica</h4>
                        <br/>

                        I posti sono limitati a 12 per turno: necessaria prenotazione
                    </p>
                </blockquote>
            </figure>

            <Space />

                <TabellaRunnering />

            <Space />

            <TitoloSezioni title={"Prezzi"} />
            <p className="text-center">
                <strong>Lezione singola</strong> 10 € <br/>
                <strong>10 lezioni</strong> <span style={{backgroundColor: "#FAD1E0"}}>intervallo</span> 50 €<br/>
                <strong>10 lezioni</strong> <span style={{backgroundColor: "#D6E3FC"}}>sera</span> 80 €<br/>
                + quota assicurativa annuale 30 €

                <Space />

                <div className="d-flex flex-column text-center align-items-center justify-content-center">
                    <TitoloSezioni title={"Multitessere"} />
                    <h4 style={{fontFamily: "Hammersmith One"}}>Scopri le nostre multitessere</h4>
                    <CardMultitessere />
                </div>
            </p>

            <Space />
                <CaroselloStandard foto1={foto1} foto2={foto2} foto3={foto8} foto4={foto4} foto5={foto5} foto6={foto6} foto7={foto7} foto8={foto3}/>
            <Space />
        </div>
    )
}


export default Runnering
