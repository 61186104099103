import React from "react";
import foto from "../../images/Calendario Cigno 20232024.jpg"
import FrecciaIndietro from "../../commons/FrecciaIndietro";
import Space from "../../commons/Space";
import zumba from "../../images/News/Zumba.jpg"
import TitoloSezioni from "../../commons/TitoloSezioni";
import "./NewsPagesFoto.css"
import ScrollToTopOnMount from "../../components/ScrollToTopOnMount";

function ZumbaNews () {
    return(
        <div className="text-center">
            <ScrollToTopOnMount />
            <FrecciaIndietro indirizzo={"/news"} />
            <TitoloSezioni title={"Corso di Zumba"} />
            <div className="container-foto-news m-auto" style={{display: "flex", flexDirection: "column"}}>
                <a style={{alignSelf: "end"}} href={zumba} download>
                    <i style={{fontSize: "2em", color: "grey", marginRight: "0.5em"}} className="bi bi-download"></i>
                </a>
                <img src={zumba} style={{width: "100%"}}></img>
            </div>
            <Space />

        </div>
    )
}
export default ZumbaNews