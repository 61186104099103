import React from "react";
import TitoloColore from "../commons/TitoloColore";
import ScrollToTopOnMount from "../components/ScrollToTopOnMount";
import "./Contatti.css"
import insta from "../icone/icons8-instagram-48.png"
import facebook from "../icone/icons8-facebook-48.png"
import {Link} from "react-router-dom";
import Space from "../commons/Space";


function Contatti() {
    return(
        <div>
            <ScrollToTopOnMount />
            <TitoloColore title={"Contatti"} backgroundColor={"#003F83"} />
            <div className="container-mappa d-flex flex-row flex-wrap" style={{marginTop: "3em"}}>
                <iframe
                    className="mappa m-auto"
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2794.903306382001!2d9.230188575987468!3d45.53215132890273!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4786b89e48618025%3A0x6f8f69b9f7883dc1!2sCentro%20Sportivo%20il%20Cigno!5e0!3m2!1sit!2sit!4v1700413093022!5m2!1sit!2sit"
                    allowFullScreen
                    loading="lazy"
                    referrerPolicy="no-referrer-when-downgrade">
                </iframe>
                <div className="text-center d-flex flex-column align-self-center m-auto" style={{ margin: "2em 0 1em 0", alignItems: "center", fontSize: "20px"}}>
                        <h1 style={{fontFamily: "Hammersmith One"}}>IL CIGNO</h1>
                        <h4>CENTRO SPORTIVO POLIVALENTE</h4>
                    <br/>
                    <p>
                        Via Fiorani, 74
                        <br/>
                        20099 Sesto San Giovanni (MI)
                    </p>
                    <br/>
                    <p>
                        tel. 02 2401 006
                        <br/>
                        visite mediche e abbigliamento 02 2426191
                    </p>
                    <br/>
                    <p>
                        e-mail:   <span/>
                        <a style={{color: "grey"}} title="mailto:info@ilcignosport.it" href="mailto:info@ilcignosport.it">info@ilcignosport.it</a>
                    </p>
                    <br/>
                    <p>
                        <Link to="https://www.instagram.com/centrosportivoilcigno/?hl=it">
                        <img src={insta}></img>
                        </Link>
                        <Link to="https://www.facebook.com/ilcignosport.it/?locale=it_IT">
                            <img src={facebook}></img>
                        </Link>
                    </p>
                </div>
                </div>
            <Space />
        </div>
    )
}

export default Contatti