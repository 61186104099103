import React, {useEffect, useState} from "react";
import CaroselloStandard from "../commons/CaroselloStandard";
import foto1 from "../images/Danza/IMG_1746.jpg"
import foto2 from "../images/Danza/IMG_1754.jpg"
import foto3 from "../images/Danza/IMG_1715.jpg"
import foto4 from "../images/Danza/IMG_1751.jpg"
import foto5 from "../images/Danza/IMG_1745.jpg"
import foto6 from "../images/Danza/IMG_1713.jpg"

import Titolo from "../commons/Titolo";
import DanzaAccordion from "../components/danza/DanzaAccordion";
import DanzaPacchettixxl from "../components/danza/DanzaPachettixxl";
import DanzaPacchettiMedia from "../components/danza/DanzaPacchettiMedia";
import DanzaInfoCorsi from "../components/danza/DanzaInfoCorsi";
import ScrollToTopOnMount from "../components/ScrollToTopOnMount";
import Space from "../commons/Space";
import InsegnantiDanza from "../components/danza/InsegnantiDanza";
import TitoloSezioni from "../commons/TitoloSezioni";
import GenitoriAttivi from "../commons/GenitoriAttivi";
import SpaceL from "../commons/SpaceL";


function Danza() {

    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };

        window.addEventListener("resize", handleResize);

        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);
    return(
        <div>
            <ScrollToTopOnMount />
            <Titolo backgroundImage={foto1} title={"Danza"}/>
            <Space />
                <DanzaAccordion />
            <div id="info"></div>
            <Space />

            <TitoloSezioni title={"Corsi"} />
            <div style={{fontSize: "20px"}} className="text-center">Direttrice della scuola di danza: Barbara Melica
            <br/></div>

            <div className="text-center" style={{fontSize: "18px"}}>
                grazie alla sua esperienza gli allievi hanno la possibilità di danzare in concorsi nazionali e internazionali
            </div><br/>
            <div style={{fontSize: "16px", marginBottom: "2em"}} className="text-center">
                Per i nuovi allievi gli inserimenti nei livelli dei corsi di base monosettimanali e bisettimanali vengono stabiliti dalle insegnanti
                <br/>
                <br/>
                Sconto del 25% per allievi maschi
                <br/>
            </div>

                <DanzaInfoCorsi />
            <Space />

            <TitoloSezioni title={"Pacchetti Experience"} />
            <SpaceL />
            {windowWidth > 900 ? (
                <DanzaPacchettixxl />
            ) : (
                <DanzaPacchettiMedia />
            )}
            <Space />
            <GenitoriAttivi />
            <Space />
                <CaroselloStandard foto1={foto1} foto2={foto2} foto3={foto3} foto4={foto4} foto5={foto5} foto6={foto6}/>
            <Space />

        </div>
    )
}


export default Danza
