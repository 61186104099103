import React from "react";
import TitoloColore from "../commons/TitoloColore";
import ScrollToTopOnMount from "../components/ScrollToTopOnMount";
import Space from "../commons/Space";
import arena from "../images/shop/Arena_logo.png"
import freddy from "../images/shop/Freddy_logo.png"
import under from "../images/shop/UnderArmor.png"
import adidas from "../images/shop/Adidas_logo.png"
import CaroselloStandard from "../commons/CaroselloStandard";
import "../styles/CaroselloStandard.css"
import foto1 from "../images/shop/IMG_9292.jpg"
import foto2 from "../images/shop/IMG_9293.jpg"
import foto3 from "../images/shop/IMG_9295.jpg"
import foto4 from "../images/shop/IMG_9297.jpg"
import foto5 from "../images/shop/IMG_9311.jpg"
import foto6 from "../images/shop/IMG_9316.jpg"
import CaroselloShop from "../commons/CaroselloShop";


function Abbigliamento() {
    return(
        <div>
            <ScrollToTopOnMount />
            <TitoloColore title={"Abbigliamento"} backgroundColor={"#003F83"} />
            <Space />
            <figure className="text-center">
                <blockquote className="blockquote">
                    <h1 >Esplora il nostro stile sportivo </h1>
                    <h2>Acquista direttamente presso il nostro centro</h2>
                    <p>
                        <br/>
                        Trova tutto ciò di cui hai bisogno per i tuoi corsi di nuoto, danza e fitness <br/>
                        Vasta selezione di abbigliamento e accessori delle migliori marche sportive
                    </p>
                    <br/>
                </blockquote>
            </figure>
            <CaroselloStandard foto1={foto1} foto2={foto2} foto3={foto3} foto4={foto4} foto5={foto5} foto6={foto6}/>
            <Space />
            <figure className="text-center">
                <blockquote className="blockquote">
                    <div className="image-container">
                        <img className="col" src={arena} style={{height: "7em", margin: "2em"}}/>
                        <img className="col" src={freddy} style={{height: "4em", margin: "2em"}}/>
                        <img className="col" src={adidas} style={{height: "6em", margin: "2em"}}/>
                        <img className="col" src={under} style={{height: "7em", margin: "2em"}}/>
                    </div>
                </blockquote>
            </figure>
            <Space />
        </div>
    )
}

export default Abbigliamento