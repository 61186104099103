import React from "react";

function TabellaFitnessMattino() {
    return(
        <div className="table-container">
            <table className="table table-bordered m-auto" style={{width: "90%"}}>
                <thead>
                <tr>
                    <th className="header-cell" scope="col">Lun</th>
                    <th className="header-cell" scope="col">Mar</th>
                    <th className="header-cell" scope="col">Mer</th>
                    <th className="header-cell" scope="col">Gio</th>
                    <th className="header-cell" scope="col">Ven</th>
                </tr>
                </thead>
                <tbody>
                <tr>
                    <td className="data-cell">
                        9:00 - 10:00 <br/> <a href="#infoFitness" style={{color: "black"}}>Postural Stretching</a>
                    </td>
                    <td className="data-cell" rowSpan={2}>
                        9:00 - 11:00 <br/> <a href="#infoFitness" style={{color: "black"}}>Total Body + Stretching</a>
                    </td>
                    <td className="data-cell" rowSpan={2}>
                        9:00 - 11:00 <br/> <a href="#infoFitness" style={{color: "black"}}>Up Body + Leg Crash</a>
                    </td>
                    <td className="data-cell">
                        9:00 - 10:00 <br/> <a href="#infoFitness" style={{color: "black"}}>Postural Stretching</a>
                    </td>
                    <td className="data-cell" rowSpan={2}>
                        9:00 - 11:00 <br/> <a href="#infoFitness" style={{color: "black"}}>Pilates + Functional Training </a>
                    </td>
                </tr>
                <tr>
                    <td className="data-cell">
                        10:00 - 11:00 <br/> <a href="#infoFitness" style={{color: "black"}}>Postural Stretching</a>
                    </td>
                    <td className="data-cell">
                        10:00 -11:00 <br/> <a href="#infoFitness" style={{color: "black"}}>Postural Stretching</a>
                    </td>
                </tr>
                </tbody>
            </table>
        </div>
    )
}

export default TabellaFitnessMattino