import React from "react";
import "../../styles/Tabella.css"

function TabellaOrariPreParto() {
    return(
        <div className="table-container">
            <table className="table table-bordered m-auto" style={{width: "90%"}}>
                <thead>
                <tr>
                    <th className="header-cell" scope="col">Lun</th>
                    <th className="header-cell" scope="col">Mar</th>
                    <th className="header-cell" scope="col">Mer</th>
                    <th className="header-cell" scope="col">Gio</th>
                    <th className="header-cell" scope="col">Ven</th>
                </tr>
                </thead>
                <tbody>
                <tr>
                    <td className="data-cell"></td>
                    <td className="data-cell"></td>
                    <td className="data-cell">11:00 - 12:00</td>
                    <td className="data-cell"></td>
                    <td className="data-cell"></td>
                </tr>
                </tbody>
            </table>
        </div>
    )
}

export default TabellaOrariPreParto