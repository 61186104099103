import React from "react";

function TabellaDanzaMono90() {
    return(
        <div className="table-container">
            <table className="table table-bordered m-auto" style={{width: "90%"}}>
                <colgroup>
                    <col style={{ width: "16.666%", minWidth: "100px"}} />
                    <col style={{ width: "16.666%", minWidth: "100px" }} />
                    <col style={{ width: "16.666%", minWidth: "100px" }} />
                    <col style={{ width: "16.666%", minWidth: "100px" }} />
                    <col style={{ width: "16.666%", minWidth: "100px" }} />
                    <col style={{ width: "16.666%", minWidth: "100px" }} />
                </colgroup>
                <thead>
                <tr>
                    <th className="header-cell" scope="col">Lun</th>
                    <th className="header-cell" scope="col">Mar</th>
                    <th className="header-cell" scope="col">Mer</th>
                    <th className="header-cell" scope="col">Gio</th>
                    <th className="header-cell" scope="col">Ven</th>
                    <th className="header-cell" scope="col">Sab</th>
                </tr>
                </thead>
                <tbody>
                <tr>
                    <td className="data-cell"></td>
                    <td className="data-cell"></td>
                    <td className="data-cell"></td>
                    <td className="data-cell"></td>
                    <td className="data-cell"></td>
                    <td className="data-cell">
                        11:30 - 13:00<br/><a href="#info" style={{color: "black"}}>Acrobatica Intermedio</a><br/>10-14 anni
                    </td>
                </tr>
                <tr>
                    <td className="data-cell"></td>
                    <td className="data-cell">
                        14:30 - 16:00<br/><a href="#info" style={{color: "black"}}>Classica Intermedio</a><br/><div style={{fontSize: "12px"}}>(inserimento con audizione)</div>
                    </td>
                    <td className="data-cell"></td>
                    <td className="data-cell"></td>
                    <td className="data-cell"></td>
                    <td className="data-cell"></td>
                </tr>
                </tbody>
            </table>
        </div>
    )
}

export default TabellaDanzaMono90