import React from "react";
import exp from "constants";
import ScrollToTopOnMount from "./ScrollToTopOnMount";

function Orari() {
    return(
        <div style={{margin: "6em 0 0 2em"}} id="orari">
            <h1 style={{fontFamily: "Hammersmith One", color: "#64A6D0"}}>ORARI</h1>
            <hr/>
            <div style={{display:"flex", flexDirection: "row"}}>
                <div style={{display: "flex", flexDirection: "column"}}>
                    <h5 style={{fontFamily: "Hammersmith One"}}>ORARIO D'APERTURA:</h5>
                    <ul>
                        <strong>Lunedì</strong>:<div className="d-block d-sm-none"/> 7 - 22.30 <br/>
                        <strong>Martedì</strong>: <div className="d-block d-sm-none"/>9 - 22.30 <br/>
                        <strong>Mercoledì</strong>: <div className="d-block d-sm-none"/>7 - 22.30 <br/>
                        <strong>Giovedì</strong>: <div className="d-block d-sm-none"/>9 - 22.30 <br/>
                        <strong>Venerdì</strong>: <div className="d-block d-sm-none"/>7 - 22.30 <br/>
                        <strong>Sabato</strong>: <div className="d-block d-sm-none"/>9 - 19.30 <br/>
                        <strong>Domenica</strong>: <div className="d-block d-sm-none"/>9 - 13.30
                    </ul>
                </div>
            <div style={{display: "flex", flexDirection: "column", marginLeft: "3em" }}>
                <h5 style={{fontFamily: "Hammersmith One"}}>ORARIO UFFICIO:</h5>
                <ul>
                    <strong>Lunedì</strong>: <div className="d-block d-sm-none"/>9 - 22 <br/>
                    <strong>Martedì</strong>: <div className="d-block d-sm-none"/>9 - 22 <br/>
                    <strong>Mercoledì</strong>: <div className="d-block d-sm-none"/>9 - 22 <br/>
                    <strong>Giovedì</strong>: <div className="d-block d-sm-none"/>9 - 22 <br/>
                    <strong>Venerdì</strong>: <div className="d-block d-sm-none"/>9 - 22 <br/>
                    <strong>Sabato</strong>: <div className="d-block d-sm-none"/>10 - 13  <br className="d-block d-sm-none"></br> / 14 - 19 <br/>
                    <strong>Domenica</strong>: <div className="d-block d-sm-none"/>9 - 13
                </ul>
            </div>
            </div>
        </div>
    )
}

export default Orari