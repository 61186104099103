import React from "react";
import TabellaOrarioRidotto from "./TabellaOrarioRidotto";
import TabellaOrarioCompleto from "./TabellaOrarioCompleto";
import "../../styles/Accordion.css"

function PalestraAccordion() {
    return(
        <div className="accordion d-flex flex-column m-auto" id="accordionFlushExample">
            <div className="accordion-item">
                <h2 className="accordion-header d-flex flex-row" id="flush-headingOne" style={{ justifyContent: "center", textAlign: "center" }}>
                    <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-expanded="false" aria-controls="flush-collapseOne">
                        ORARIO PIENO
                    </button>
                </h2>
                <div id="flush-collapseOne" className="accordion-collapse collapse show" aria-labelledby="flush-headingOne" data-bs-parent="#accordionFlushExample">
                    <div className="accordion-body d-flex flex-row flex-wrap align-items-center justify-content-center">
                        <TabellaOrarioCompleto />
                        <hr/>
                        <p className="text-center" style={{marginTop: "2em", padding: "1em 5em 1em 5em"}}>
                            <strong>1 mese</strong> 109 € + iscrizione 10 €<br/>
                            <strong>3 mesi</strong> 264 € + iscrizione 10 €<br/>
                            <strong>6 mesi</strong> 468 € + iscrizione 20 €<br/>
                            <strong>12 mesi</strong> 588 € + iscrizione 30 €<br/>
                        </p>
                    </div>

                </div>
            </div>
            <div className="accordion-item">
                <h2 className="accordion-header" id="flush-headingTwo">
                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTwo" aria-expanded="false" aria-controls="flush-collapseTwo">
                        ORARIO RIDOTTO
                    </button>
                </h2>
                <div id="flush-collapseTwo" className="accordion-collapse collapse" aria-labelledby="flush-headingTwo" data-bs-parent="#accordionFlushExample">
                    <div className="accordion-body d-flex flex-row flex-wrap align-items-center justify-content-center">
                        <TabellaOrarioRidotto />
                        <hr/>
                        <p className="text-center" style={{marginTop: "2em", padding: "1em 5em 1em 5em"}}>
                            <strong>1 mese</strong> 89 € + iscrizione 10 €<br/>
                            <strong>3 mesi</strong> 240 € + iscrizione 10 €<br/>
                            <strong>6 mesi</strong> 348 € + iscrizione 20 €<br/>
                            <strong>12 mesi</strong> 480 € + iscrizione 30 €<br/>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PalestraAccordion