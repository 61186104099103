import React from "react";
import "../../styles/Tabella.css"


function TabellaNLOrarioCompleto() {
    return(
        <div className="table-container">
            <table className="table table-bordered m-auto" style={{width: "90%"}}>
                <thead>
                <tr>
                    <th className="header-cell" scope="col">Lun</th>
                    <th className="header-cell" scope="col">Mar</th>
                    <th className="header-cell" scope="col">Mer</th>
                    <th className="header-cell" scope="col">Gio</th>
                    <th className="header-cell" scope="col">Ven</th>
                    <th className="header-cell" scope="col">Sab</th>
                    <th className="header-cell" scope="col">Dom</th>
                </tr>
                </thead>
                <tbody>
                <tr>
                    <td className="data-cell">7:00 - 22:00</td>
                    <td className="data-cell">9:00 - 22:00</td>
                    <td className="data-cell">7:00 - 22:00</td>
                    <td className="data-cell">9:00 - 22:00</td>
                    <td className="data-cell">7:00 - 22:00</td>
                    <td className="data-cell">9:00 - 19:00</td>
                    <td className="data-cell">9:00 - 13:00</td>
                </tr>
                </tbody>
            </table>
        </div>
    )
}

export default TabellaNLOrarioCompleto