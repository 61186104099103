import React from "react";
import CaroselloStandard from "../../commons/CaroselloStandard";
import foto1 from "../../images/Nuoto/Riabilitazione/foto1.jpg"
import foto2 from "../../images/Nuoto/Riabilitazione/foto3.jpg"
import foto3 from "../../images/Nuoto/Riabilitazione/foto7.jpg"
import Titolo from "../../commons/Titolo";
import ScrollToTopOnMount from "../../components/ScrollToTopOnMount";
import {BrowserRouter, useNavigate} from "react-router-dom";
import FrecciaIndietro from "../../commons/FrecciaIndietro";
import TitoloSezioni from "../../commons/TitoloSezioni";
import Space from "../../commons/Space";
import SpaceL from "../../commons/SpaceL";


function RiabilitazioneMotoria() {
    const navigate = useNavigate()
    return(
        <div>
            <ScrollToTopOnMount />
            <Titolo backgroundImage={foto1} title={"Riabilitazione Motoria"}/>
            <FrecciaIndietro indirizzo={"/piscina"} />
            <TitoloSezioni title={"Prestazioni professionali"} />
            <br/>

            <figure className="text-center">
                <blockquote className="blockquote">
                    <h5>
                        IDROKINESITERAPIA
                        <br/>
                        GINNASTICA POSTURALE
                        <br/>
                        GINNASTICA IN ACQUA
                        <br/>
                        MASSOTERAPIA
                        <br/>
                        PERSONAL TRAINING
                        <br/>
                        KINESIOTAPING
                        <br/>
                        ANALISI DELLA COMPOSIZIONE CORPOREA
                        <br/>
                        PEDANA TANITA
                    </h5>
                    <SpaceL />
                    <p>
                        Rivolgiti in palestra e chiedi di <strong>Antonio</strong> o <strong>Simone</strong>
                        <br/>
                        <strong>Chiama</strong> o <strong>scrivi</strong> un messaggio <br/>3315824140 / 3501516715
                        <br/>
                        Scrivi una <strong>mail</strong> a <a style={{color: "grey"}} title="mailto:ilcoachtrainer@yahoo.it" href="mailto:ilcoachtrainer@yahoo.it">ilcoachtrainer@yahoo.it</a>
                    </p>
                </blockquote>
            </figure>
            <Space />
                <CaroselloStandard foto1={foto1} foto2={foto2} foto3={foto3}/>
            <Space />

        </div>
    )
}


export default RiabilitazioneMotoria