import React from "react";
import CaroselloStandard from "../commons/CaroselloStandard";
import foto1 from "../images/Palestra/IMG_9287.jpg"
import foto2 from "../images/Palestra/IMG_9288.jpg"
import foto3 from "../images/Palestra/IMG_9289.jpg"
import foto4 from "../images/Palestra/IMG_1287.jpg"
import Titolo from "../commons/Titolo";
import PalestraAccordion from "../components/palestra/PalestraAccordion";
import ScrollToTopOnMount from "../components/ScrollToTopOnMount";
import Space from "../commons/Space";
import CardMultitessere from "../components/CardMultitessere";
import TitoloSezioni from "../commons/TitoloSezioni";


function Palestra() {
    return(
        <div>
            <ScrollToTopOnMount />
            <Titolo backgroundImage={foto1} title={"Palestra"}/>
            <Space />

            <PalestraAccordion />
            <Space />
            <figure className="text-center">
                <blockquote className="blockquote">
                    <p>
                        Accesso libero ai due ambienti: <strong>sala cardio</strong> e <strong>sala pesi</strong>
                        <br/>
                        <strong>Scheda personalizzata</strong> per tutti gli iscritti
                        <br/>
                        Personale di sala sempre presente
                        <br/>
                        <br/>
                        Gruppo organizzato giovanissimi 11-14 anni: martedì e venerdì dalle 17 alle 18
                    </p>
                </blockquote>
            </figure>
            <Space />
                <CaroselloStandard foto1={foto1} foto2={foto2} foto3={foto3} foto4={foto4}/>
            <Space />
            <div className="d-flex flex-column text-center align-items-center justify-content-center">
                <TitoloSezioni title={"Multitessere"} />
                <h4 style={{fontFamily: "Hammersmith One"}}>Scopri le nostre multitessere</h4>
                <CardMultitessere />
            </div>
            <Space />
        </div>
    )
}


export default Palestra
