import React from "react";
import "../../styles/Tabella.css"


function TabellaRunnering() {
    return(
        <div className="table-container" style={{margin: "0 1em 0 1em"}}>
            <table className="table table-bordered m-auto" style={{width: "90%"}}>
                <colgroup>
                    <col style={{ width: "16.666%", minWidth: "50px"}} />
                    <col style={{ width: "16.666%", minWidth: "50px" }} />
                    <col style={{ width: "16.666%", minWidth: "50px" }} />
                    <col style={{ width: "16.666%", minWidth: "50px" }} />
                    <col style={{ width: "16.666%", minWidth: "50px" }} />
                    <col style={{ width: "16.666%", minWidth: "50px" }} />
                </colgroup>
                <thead>
                <tr>
                    <th className="header-cell" scope="col">Lun</th>
                    <th className="header-cell" scope="col">Mar</th>
                    <th className="header-cell" scope="col">Mer</th>
                    <th className="header-cell" scope="col">Gio</th>
                    <th className="header-cell" scope="col">Ven</th>
                    <th className="header-cell" scope="col">Sab</th>
                </tr>
                </thead>
                <tbody>
                <tr>
                    <td className="data-cell"></td>
                    <td className="data-cell"></td>
                    <td className="data-cell"></td>
                    <td className="data-cell"></td>
                    <td className="data-cell"></td>
                    <td className="data-cell fascia-b">10:15 - 11:15</td>
                </tr>
                <tr>
                    <td className="data-cell"></td>
                    <td className="data-cell"></td>
                    <td className="data-cell"></td>
                    <td className="data-cell"></td>
                    <td className="data-cell"></td>
                    <td className="data-cell fascia-b">11:15 - 12:15</td>
                </tr>
                <tr>
                    <td className="data-cell fascia-b">13:15 - 14:15</td>
                    <td className="data-cell fascia-b">13:15 - 14:15</td>
                    <td className="data-cell fascia-b">13:15 - 14:15</td>
                    <td className="data-cell fascia-b">13:15 - 14:15</td>
                    <td className="data-cell fascia-b">13:15 - 14:15</td>
                    <td className="data-cell"></td>
                </tr>
                <tr>
                    <td className="data-cell fascia-c">18:30 - 19:30</td>
                    <td className="data-cell"></td>
                    <td className="data-cell fascia-c">18:30 - 19:30</td>
                    <td className="data-cell fascia-c">18:30 - 19:30</td>
                    <td className="data-cell"></td>
                    <td className="data-cell"></td>
                </tr>
                <tr>
                    <td className="data-cell fascia-c">19:30 - 20:30</td>
                    <td className="data-cell"></td>
                    <td className="data-cell fascia-c">19:30 - 20:30</td>
                    <td className="data-cell fascia-c">19:30 - 20:30</td>
                    <td className="data-cell"></td>
                    <td className="data-cell"></td>
                </tr>
                </tbody>
            </table>
        </div>
    )
}

export default TabellaRunnering