import React from "react";
import TabellaOrariAcq from "./TabellaOrariAcq";
import TabellaOrariAvv from "./TabellaOrariAvv";
import TabellaOrariBR from "./TabellaOrariBR";
import TabellaOrariAdulti from "./TabellaOrariAdulti";
import "../../styles/Accordion.css"
import TabellaOrariPreParto from "./TabellaPreParto";
import TabellaOrariPreAgo from "./TabellaPreAgo";
import Space from "../../commons/Space";
import PrezziAdulti from "./PrezziAdulti";


function NuotoCorsiSezioni() {
    return(
        <div className="accordion d-flex flex-column m-auto" id="accordionFlushExample">
                <div className="accordion-item">
                    <h2 className="accordion-header d-flex flex-row" id="flush-headingOne" style={{ justifyContent: "center", textAlign: "center" }}>
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-expanded="false" aria-controls="flush-collapseOne">
                            ACQUATICITÀ (6 mesi - 3 anni)
                        </button>

                    </h2>
                    <div id="flush-collapseOne" className="accordion-collapse collapse" aria-labelledby="flush-headingOne" data-bs-parent="#accordionFlushExample">
                        <div className="accordion-body">
                            <TabellaOrariAcq />
                            <hr/>
                            <p className="text-center" style={{marginTop: "2em"}}>
                                <br/>
                                <strong>4 mesi</strong> (18 lezioni) 288 € + iscrizione 15 €
                                <br />
                                <br/>
                                Possibilità di prova nuoto: 15€
                                <br/>
                                in caso di iscrizione il costo della prova verrà scalato dall'abbonamento
                                <br/>
                            </p>
                        </div>
                    </div>
                </div>
                <div className="accordion-item">
                    <h2 className="accordion-header" id="flush-headingTwo">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTwo" aria-expanded="false" aria-controls="flush-collapseTwo">
                            AVVIAMENTO SCUOLA NUOTO (3 - 5 anni)
                        </button>
                    </h2>
                    <div id="flush-collapseTwo" className="accordion-collapse collapse" aria-labelledby="flush-headingTwo" data-bs-parent="#accordionFlushExample">
                        <div className="accordion-body">
                            <TabellaOrariAvv />
                            <hr/>
                            <p className="text-center" style={{marginTop: "2em"}}>
                                <div style={{fontFamily: "Hammersmith One", fontSize: "20px"}}>MONOSETTIMANALE</div>
                                <strong>4 mesi</strong> (18 lezioni) 288 € + iscrizione 15 €<br/>
                                <br/>
                                <div style={{fontFamily: "Hammersmith One", fontSize: "20px"}}>BISETTIMANALE</div>
                                <strong>3 mesi</strong> (24 lezioni) 288 € + iscrizione 10 €<br/>
                                <strong>6 mesi</strong> (48 lezioni) 456 € + iscrizione 20 €<br/>
                                <strong>9 mesi</strong> (72 lezioni) 588 € + iscrizione 30 €<br/>
                                <br/>
                                *giorni a scelta
                                <br />
                                <br/>
                                Possibilità di prova nuoto: 15€
                                <br/>
                                in caso di iscrizione il costo della prova verrà scalato dall'abbonamento
                                <br/>
                            </p>
                        </div>
                    </div>
                </div>
                <div className="accordion-item">
                    <h2 className="accordion-header" id="flush-headingThree">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseThree" aria-expanded="false" aria-controls="flush-collapseThree">
                            BAMBINI E RAGAZZI (6 - 14 anni)
                        </button>
                    </h2>
                    <div id="flush-collapseThree" className="accordion-collapse collapse" aria-labelledby="flush-headingThree" data-bs-parent="#accordionFlushExample">
                        <div className="accordion-body">
                            <TabellaOrariBR />
                            <hr/>
                            <p className="text-center" style={{marginTop: "2em"}}>
                                <div style={{fontFamily: "Hammersmith One", fontSize: "20px"}}>MONOSETTIMANALE</div>
                                <strong>3 mesi</strong> (12 lezioni) 204 € + iscrizione 10 €<br/>
                                <strong>6 mesi</strong> (24 lezioni) 312 € + iscrizione 20 €<br/>
                                <strong>9 mesi</strong> (36 lezioni) 396 € + iscrizione 30 €<br/>
                                <br/>
                                <div style={{fontFamily: "Hammersmith One", fontSize: "20px"}}>BISETTIMANALE</div>
                                <strong>3 mesi</strong> (24 lezioni) 252 € + iscrizione 10 €<br/>
                                <strong>6 mesi</strong> (48 lezioni) 372 € + iscrizione 20 €<br/>
                                <strong>9 mesi</strong> (72 lezioni) 492 € + iscrizione 30 €<br/>
                                <br/>
                                *giorni a scelta
                            </p>
                        </div>
                    </div>
                </div>
            <div className="accordion-item">
                <h2 className="accordion-header" id="flush-headingFive">
                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseFive" aria-expanded="false" aria-controls="flush-collapseFive">
                        PREAGONISTICA
                    </button>
                </h2>
                <div id="flush-collapseFive" className="accordion-collapse collapse" aria-labelledby="flush-headingFive" data-bs-parent="#accordionFlushExample">
                    <div className="accordion-body">
                        <TabellaOrariPreAgo />
                        <hr/>
                        <p className="text-center" style={{padding: "0 3em 0 3em"}}>
                            Corso di <strong>nuoto avanzato</strong> rivolto ai ragazzi dai 10 ai 15 anni che sappiano già nuotare tutti i quattro stili
                            <br/>
                            <br/>Possibilità di partecipare a competizioni <strong>FIN/UISP</strong>
                            <br/>
                            <br/>
                            <strong>4 allenamenti</strong> a settimana da settembre a giugno <br/>+ <strong>2 allenamenti</strong> in palestra facoltativi: mercoledì 16-18, sabato 10-16
                            <br/>
                            <br/>
                            Durante i mesi estivi e il periodo natalizio accesso al nuoto libero
                            <br/>
                            <br/>
                            Allenatore Stefano Moscardi (brevettato capo allenatore FIN)
                            <br/>
                            <br/>

                            <strong>Quota annuale complessiva</strong> 900 € + iscrizione 30 €
                            <br/>
                        </p>
                    </div>
                </div>
            </div>
                <div className="accordion-item">
                    <h2 className="accordion-header" id="flush-headingFour">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseFour" aria-expanded="false" aria-controls="flush-collapseFour">
                            ADULTI
                        </button>
                    </h2>
                    <div id="flush-collapseFour" className="accordion-collapse collapse" aria-labelledby="flush-headingFour" data-bs-parent="#accordionFlushExample">
                        <div className="accordion-body">
                            <TabellaOrariAdulti />
                            <hr/>
                            <PrezziAdulti />
                        </div>
                    </div>
                </div>
                <div className="accordion-item">
                    <h2 className="accordion-header" id="flush-headingSix">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseSix" aria-expanded="false" aria-controls="flush-collapseSix">
                            PRE PARTO
                        </button>
                    </h2>
                    <div id="flush-collapseSix" className="accordion-collapse collapse" aria-labelledby="flush-headingSix" data-bs-parent="#accordionFlushExample">
                        <div className="accordion-body">
                            <TabellaOrariPreParto />
                            <hr/>
                            <p className="text-center" style={{marginTop: "2em"}}>
                                Il corso prevede una parte di ginnastica a secco e una parte in vasca (acqua alta e acqua bassa)
                                <br/>
                                si richiede buona capacità di galleggiamento
                                <br/>
                                <br/>
                                <b>cosa portare?</b>
                                <br/>
                                abbigliamento ginnico, costume, cuffia, asciugamano, ciabatte
                                <br/>

                                <br/>
                                <strong>10 lezioni</strong> (entro 5 mesi) 95 € + iscrizione 10 €
                                <br/>
                                Possibilità di acquistare ingressi singoli
                                <br/>
                                <br/>
                                *richiesto certificato ginecologico
                            </p>
                        </div>
                    </div>
                </div>

        </div>
    )
}

export default NuotoCorsiSezioni
