import React from "react";
import vasca from "../../images/Spa/IMG_8107.jpg"
import bturco from "../../images/Spa/IMG_8562.jpg"
import sauna from "../../images/Spa/IMG_8560.jpg"
import fredda from "../../images/Spa/IMG_8112.jpg"
import lettini from "../../images/Spa/IMG_8118.jpg"

function CaroselloSpa() {
    return(
        <div id="carouselExampleCaptions" className="carousel my-carousel slide m-auto" data-bs-ride="false" style={{margin: "1em", borderRadius: "1em"}}>
            <div className="carousel-indicators my-carousel">
                <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1"></button>
                <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="1" aria-label="Slide 2"></button>
                <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="2" aria-label="Slide 3"></button>
                <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="3" aria-label="Slide 4"></button>
                <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="4" aria-label="Slide 5"></button>
            </div>
            <div className="carousel-inner my-carousel" style={{borderRadius: "1em"}}>
                <div className="carousel-item my-carousel active">
                    <img src={vasca} className="d-block w-100" alt="..."/>
                    <div className="carousel-caption">
                        <h5>Vasca termica </h5>
                        <p>con idromassaggio SPA ad aria e acqua</p>
                    </div>
                </div>
                <div className="carousel-item my-carousel">
                    <img src={sauna} className="d-block w-100" alt="..."/>
                    <div className="carousel-caption">
                        <h5>Sauna</h5>
                        <br/>

                    </div>
                </div>
                <div className="carousel-item my-carousel">
                    <img src={bturco} className="d-block w-100" alt="..."/>
                    <div className="carousel-caption">
                        <h5>Bagno Turco</h5>
                        <br/>

                    </div>
                </div>
                <div className="carousel-item my-carousel">
                    <img src={fredda} className="d-block w-100" alt="..."/>
                    <div className="carousel-caption">
                        <h5>Vasca Fredda</h5>
                        <br/>

                    </div>
                </div>
                <div className="carousel-item my-carousel">
                    <img src={lettini} className="d-block w-100" alt="..."/>
                    <div className="carousel-caption">
                        <h5>Lettini</h5>
                        <br/>
                        <p> </p>
                    </div>
                </div>
            </div>
            <button className="carousel-control-prev my-carousel" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="prev">
                <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                <span className="visually-hidden">Previous</span>
            </button>
            <button className="carousel-control-next my-carousel" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="next">
                <span className="carousel-control-next-icon" aria-hidden="true"></span>
                <span className="visually-hidden">Next</span>
            </button>
        </div>
    )
}

export default CaroselloSpa;
